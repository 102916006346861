import styles from './HeaderMain.module.css'
import classNames from 'classnames'

import { useLocation } from 'react-router-dom'
import { PATH } from '../../constants/path'

import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { companySelector } from '../../redux/selectors/company-selector'
import { setCurrentCar, setIsVisibleModalCar } from '../../redux/reducers/cars'
import {
  setCurrentUser,
  setCurrentUserByRequest,
  setIsVisibleModalUser,
} from '../../redux/reducers/users'
import {
  setCurrentReport,
  setCurrentReportId,
  setIsVisibleModalReport,
} from '../../redux/reducers/reports'
import { setCurrentService } from '../../redux/reducers/services'
import { setCurrentNote } from '../../redux/reducers/notes'
import {
  setCurrentClient,
  setCurrentClientByRequest,
  setIsVisibleModalClient,
} from '../../redux/reducers/clients'
import { clearPhones } from '../../redux/reducers/phones'
import {
  setCurrentContract,
  setIsVisibleModalContract,
} from '../../redux/reducers/documents/contracts'
import {
  setCurrentPart,
  setIsVisibleModalPart,
} from '../../redux/reducers/parts'
import { useState } from 'react'
import {
  setCurrentReportCommon,
  setIsVisibleModalReportCommon,
  setSelectedReportsTab,
} from '../../redux/reducers/reports-common'
import { reportsCommonSelector } from '../../redux/selectors/reports-common-selector'

const TITLE = {
  [PATH.DOCUMENTS]: 'Документы',
  [PATH.REPORTS]: 'Отчёты',
  [PATH.MY_REPORTS]: 'Мои отчёты',
  [PATH.EMPLOYEES]: 'Сотрудники',
  [PATH.CARS]: 'Транспорт',
  [PATH.CLIENTS]: 'Клиенты',
  [PATH.PROFILE]: 'Профиль',
  [PATH.SETTINGS]: 'Настройки',
  [PATH.PARTS]: 'Запчасти',
}

function HeaderMain() {
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()
  const { currentReportTab } = useSelector(reportsCommonSelector)

  const isVisibleAddButton =
    location.pathname !== PATH.SETTINGS &&
    location.pathname !== PATH.REPORTS &&
    location.pathname !== PATH.MY_REPORTS

  const title = TITLE[location.pathname as keyof typeof TITLE]

  const handleClick = () => {
    switch (location.pathname) {
      case PATH.CARS:
        dispatch(setCurrentCar(null))
        dispatch(setCurrentService(null))
        dispatch(setCurrentNote([]))
        dispatch(setIsVisibleModalCar(true))
        break
      case PATH.EMPLOYEES:
        dispatch(setCurrentUser(null))
        dispatch(setCurrentUserByRequest(null))
        dispatch(setIsVisibleModalUser(true))
        break
      case PATH.REPORTS:
        dispatch(setCurrentReport(null))
        dispatch(setCurrentReportCommon(null))
        dispatch(setCurrentReportId(null))

        if (currentReportTab === '1') {
          dispatch(setIsVisibleModalReport(true))
        } else {
          dispatch(setIsVisibleModalReportCommon(true))
        }
        break
      case PATH.CLIENTS:
        dispatch(setCurrentClient(null))
        dispatch(setCurrentClientByRequest(null))
        dispatch(setIsVisibleModalClient(true))
        dispatch(clearPhones())
        break
      case PATH.DOCUMENTS:
        dispatch(setCurrentContract(null))
        dispatch(setIsVisibleModalContract(true))
        break
      case PATH.PARTS:
        dispatch(setCurrentPart(null))
        dispatch(setIsVisibleModalPart(true))
        break
      default:
        break
    }
  }

  const handleTabChange = (event: any) => {
    dispatch(setSelectedReportsTab(event.target.value))
  }

  return (
    <div
      className={classNames(
        location.pathname === PATH.REPORTS ? styles.headerWrapper : '',
        'row align-items-center mb-3'
      )}
    >
      <div
        className={classNames(styles.radioTabsWrapper, 'col-7 col-sm-7 mb-3')}
      >
        {location.pathname === PATH.REPORTS ? (
          <>
            <div
              className="col-12 col-sm-8 mb-3 align-items-center"
              style={{ textAlign: 'left' }}
            >
              <div
                className={classNames(
                  styles.h3,
                  styles.radioTabs,
                  'h3 justify-content-start align-items-end mb-0 mt-2'
                )}
              >
                <div className="mr-5">
                  <input
                    name="reports_tab"
                    id="reports_tab_1"
                    type="radio"
                    value="1"
                    checked={currentReportTab === '1'}
                    onChange={handleTabChange}
                  />
                  <label className={styles.labelHeader} htmlFor="reports_tab_1">
                    Транспортные
                  </label>
                </div>
                <div>
                  <input
                    name="reports_tab"
                    id="reports_tab_2"
                    type="radio"
                    value="2"
                    checked={currentReportTab === '2'}
                    onChange={handleTabChange}
                  />
                  <label className={styles.labelHeader} htmlFor="reports_tab_2">
                    Общие
                  </label>
                </div>
              </div>
            </div>
          </>
        ) : (
          <h2 className={`mb-0 ${styles.h2}`}>{title}</h2>
        )}
      </div>
      {(isVisibleAddButton || location.pathname === PATH.REPORTS) && (
        <div
          className={classNames(
            location.pathname === PATH.REPORTS ? styles.buttonWrapper : '',
            'col-5 col-sm-5 mb-3'
          )}
          style={{ textAlign: 'right' }}
        >
          <a
            onClick={handleClick}
            className={`${styles.btn} ${styles.btnSmall}`}
          >
            {location.pathname === PATH.REPORTS ? 'Новый отчёт' : '+ Добавить'}
          </a>
        </div>
      )}
    </div>
  )
}

export default HeaderMain
