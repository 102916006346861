import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import HeaderMain from '../../components/header-main/HeaderMain'

import styles from './Clients.module.css'
import classNames from 'classnames'
import { getRole } from '../../utils/getRole'
import {
  Client,
  fetchClient,
  fetchClients,
  setCurrentClient,
  setIsVisibleDeleteModalClient,
  setIsVisibleModalClient,
} from '../../redux/reducers/clients'
import { clientsSelector } from '../../redux/selectors/clients-selector'
import { useOutsideClick } from '../../hooks/useOutsideClick'
import EditButton from '../../components/buttons/actions-button/EditButton'
import DeleteButton from '../../components/buttons/actions-button/DeleteButton'

interface VisibleDropdowns {
  [key: number]: boolean
}

function Clients() {
  const dispatch = useDispatch<AppDispatch>()
  const { clients } = useSelector(clientsSelector)
  const [selectedCountry, setSelectedCountry] = useState('all')
  const [visibleDropdowns, setVisibleDropdowns] = useState<VisibleDropdowns>({})

  const ref = useOutsideClick(() => {
    const newVisibleDropdowns = Object.keys(visibleDropdowns).reduce(
      (acc, key) => {
        //@ts-ignore
        acc[key] = false
        return acc
      },
      {} as VisibleDropdowns
    )

    setVisibleDropdowns(newVisibleDropdowns)
  })

  const toggleDropdown = (clientId: number) => {
    setVisibleDropdowns((prevVisibleDropdowns) => ({
      ...prevVisibleDropdowns,
      [clientId]: !prevVisibleDropdowns[clientId],
    }))
  }

  const handleEditClient = async (item: Client) => {
    await dispatch(fetchClient(item.id))
    dispatch(setIsVisibleModalClient(true))
  }

  const handleDeleteClient = (item: Client) => {
    dispatch(setCurrentClient(item))
    dispatch(setIsVisibleDeleteModalClient(true))
  }

  const handleCountryChange = (countryIso: string) => {
    setSelectedCountry(countryIso)
  }

  const filteredClients = clients.filter((client) => {
    return selectedCountry === 'all' || client.country.iso === selectedCountry
  })

  useEffect(() => {
    dispatch(fetchClients())
  }, [])

  return (
    <div className={styles.accountContainer}>
      <div className={styles.accountWideContent}>
        <div className={styles.accountWideContentContainer}>
          <HeaderMain />

          <div className="row">
            <div className="col-12 offset-xl-1 col-xl-10">
              <div className={classNames(styles.small2, 'mb-5')}>
                <div>
                  <input
                    type="radio"
                    name="country_filter"
                    id="country_0"
                    checked={selectedCountry === 'all'}
                    onChange={() => handleCountryChange('all')}
                  />
                  <label className={styles.countryLabel} htmlFor="country_0">
                    Все страны
                  </label>

                  <input
                    type="radio"
                    name="country_filter"
                    id="country_1"
                    onChange={() => handleCountryChange('BY')}
                  />
                  <label className={styles.countryLabel} htmlFor="country_1">
                    Беларусь
                  </label>

                  <input
                    type="radio"
                    name="country_filter"
                    id="country_2"
                    onChange={() => handleCountryChange('RU')}
                  />
                  <label className={styles.countryLabel} htmlFor="country_2">
                    Россия
                  </label>
                </div>
              </div>

              {filteredClients.map((item) => {
                return (
                  <div
                    className={classNames(
                      styles.containerItem,
                      styles.border10,
                      styles.bxShadow,
                      'pb-3',
                      'pl-3',
                      'pr-3',
                      'mb-3'
                    )}
                  >
                    <div className="row align-items-center">
                      <div
                        onClick={() => handleEditClient(item)}
                        className="col-12 col-sm-7 col-md-5 col-lg-6 col-xl-4 mt-2"
                      >
                        <div className="mb-1">
                          <a className={styles.client}>
                            <div className={styles.clientLogo}>
                              <img
                                className={
                                  item?.logo?.original ? '' : styles.opacity02
                                }
                                src={
                                  item?.logo?.original ||
                                  'img/clients/icon-flag.svg'
                                }
                              />
                            </div>
                            <div
                              className={classNames(
                                styles.name,
                                styles.ellipsis2
                              )}
                            >
                              <b>{item.entity_name || item.name}</b>
                            </div>
                          </a>
                        </div>
                        <span className={classNames(styles.small2, 'mr-2')}>
                          <a className={styles.colorGray} href="#">
                            123123{' '}
                          </a>
                        </span>
                        <span
                          className={classNames(
                            styles.small2,
                            styles.colorGray
                          )}
                        >
                          {`${item.country.name}, ${item.city.name}`}
                        </span>
                      </div>
                      <div className="col-12 col-sm-5 col-md-4 col-lg-3 col-xl-3 mt-2">
                        <a
                          target="_blank"
                          href={`https://yandex.ru/maps/?text=${item.country.name},${item.city.name},${item.address}`}
                          className={classNames(
                            styles.colorBlack,
                            styles.address,
                            styles.small2
                          )}
                          rel="noreferrer"
                        >
                          <i
                            className={classNames(
                              styles.icon,
                              styles.icon20,
                              styles.iconLocation
                            )}
                          />
                          <div>
                            <b>{item.city.name}</b>{' '}
                            <span
                              className={classNames(
                                styles.bgTurquoise,
                                styles.borderRadius5,
                                'd-inline-block pl-1 pr-1 pt-0 pb-0'
                              )}
                            >
                              {item.district}
                            </span>
                            <br />
                            {item.address}
                          </div>
                        </a>
                      </div>
                      <div className="d-none d-xl-block col-xl-3 mt-2">
                        <a
                          className={classNames(
                            styles.small2,
                            styles.ellipsis2
                          )}
                          href={`mailto:${item.email}`}
                        >
                          {item.email}
                        </a>
                      </div>
                      <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-1 mt-2">
                        {!!item.phones.length && (
                          <>
                            {item.phones.length === 1 ? (
                              item.phones.map((number, id) => (
                                <a
                                  key={String(number) + id}
                                  href={`tel:${number}`}
                                  className={classNames(
                                    styles.btn,
                                    styles.btnSmall,
                                    styles.btnIcon,
                                    styles.btnDropdown
                                  )}
                                >
                                  <i
                                    className={classNames(
                                      styles.icon,
                                      styles.iconPhoneWhite
                                    )}
                                  ></i>
                                </a>
                              ))
                            ) : (
                              <div className={styles.dropdown}>
                                <div
                                  key={item.id}
                                  onClick={() => {
                                    toggleDropdown(item.id)
                                  }}
                                  className={classNames(
                                    styles.btn,
                                    styles.btnBlue,
                                    styles.btnSmall,
                                    styles.btnIcon,
                                    styles.btnDropdown
                                  )}
                                >
                                  {visibleDropdowns[item.id] && (
                                    <div
                                      className={styles.addedButton}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        const newVisibleDropdowns = Object.keys(
                                          visibleDropdowns
                                        ).reduce((acc, key) => {
                                          //@ts-ignore
                                          acc[key] = false
                                          return acc
                                        }, {} as VisibleDropdowns)

                                        setVisibleDropdowns(newVisibleDropdowns)
                                      }}
                                    />
                                  )}
                                  <i
                                    className={classNames(
                                      styles.icon,
                                      styles.iconPhoneWhite
                                    )}
                                  />
                                </div>
                                {visibleDropdowns[item.id] && (
                                  <div
                                    className={classNames(
                                      styles.dropdownContent,
                                      styles.small
                                    )}
                                    ref={ref}
                                  >
                                    {item.phones.map(
                                      ({ id, job, name, number }) => (
                                        <a
                                          key={String(number) + id + name + job}
                                          href={`tel:${number}`}
                                          className="nowrap"
                                          onClick={() =>
                                            toggleDropdown(item.id)
                                          }
                                          //@ts-ignore
                                        >
                                          <i
                                            className={classNames(
                                              styles.icon,
                                              styles.icon16,
                                              styles.iconPhone
                                            )}
                                          ></i>
                                          {number}
                                          <br />
                                          <span
                                            className={classNames(
                                              styles.colorBlack,
                                              styles.small2
                                            )}
                                          >
                                            {`${name} ${job}`}
                                          </span>
                                        </a>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div
                        className="col-6 col-sm-6 col-md-1 col-lg-1 col-xl-1 mt-2"
                        style={{ textAlign: 'right' }}
                      >
                        <EditButton onClick={() => handleEditClient(item)} />
                        <DeleteButton
                          onClick={() => handleDeleteClient(item)}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Clients
