import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'

import styles from '../ReportModal.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../redux/store'
import {
  Expense,
  fetchDeleteExpenseImage,
} from '../../../../redux/reducers/reports'
import classNames from 'classnames'
import { reportsSelector } from '../../../../redux/selectors/reports-selector'

import { toast } from 'react-toastify'
import { IMAGE_TYPE } from '../../../../constants/file-types'
import DateTimePicker from '../../../common/date-picker/DatePicker'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import {
  fetchAddExpense,
  setCurrentExpenses,
  setVisibleDeleteModalExpenses,
} from '../../../../redux/reducers/reports'
import EditButton from '../../../buttons/actions-button/EditButton'
import DeleteButton from '../../../buttons/actions-button/DeleteButton'
import { onlyNumberRegularCheck } from '../../../../utils/onlyNumberRegularCheck'

import moment from 'moment'
type ExpenseType =
  | 'fuel'
  | 'tollRoad'
  | 'parking'
  | 'phone'
  | 'wash'
  | 'tire'
  | 'other'

const TYPE_EXPENSES: Record<ExpenseType, string> = {
  fuel: 'Топливо',
  tollRoad: 'Платная дорога',
  parking: 'Парковка',
  phone: 'Телефон',
  wash: 'Мойка',
  tire: 'Шиномонтаж',
  other: 'Иное',
}

type FileArray = File[]

const typePayment = {
  cash: 'Нал',
  cash_check: 'Нал (чек)',
  card: 'Карта',
  card_check: 'Карта (чек)',
  fuel_card: 'Топл. карта',
}

const Expenses: FC<{
  expenses: Expense
  currentEditingId: number | null
  setCurrentEditingId: (id: number | null) => void
  setIsVisibleExpenses: (value: boolean) => void
}> = ({
  expenses,
  currentEditingId,
  setCurrentEditingId,
  setIsVisibleExpenses,
}) => {
  const isVisibleEditExpenses = currentEditingId === expenses.id
  const dispatch = useDispatch<AppDispatch>()
  const handleDeleteExpenses = (expenses: Expense) => {
    dispatch(setCurrentExpenses(expenses))
    dispatch(setVisibleDeleteModalExpenses(true))
  }

  const handleEditClick = (e: any) => {
    if (isVisibleEditExpenses) {
      setCurrentEditingId(null)
    } else {
      if (expenses?.id) setCurrentEditingId(expenses.id)
      setIsVisibleExpenses(false)
    }
  }
  const textareaRef = useRef(null)

  const [amountExpenses, setAmountExpenses] = useState(expenses?.amount)
  const [currencyExpenses, setCurrencyExpenses] = useState(expenses?.currency)
  const [dateExpenses, setDateExpenses] = useState(expenses?.date || '')
  const [descriptionExpenses, setDescriptionExpenses] = useState(
    expenses?.description
  )
  const [paymentTypeExpenses, setPaymentTypeExpenses] = useState(
    expenses.payment_type
  )
  const [quantityExpenses, setQuantityExpenses] = useState(expenses?.quantity)
  const [typeExpenses, setTypeExpenses] = useState(expenses.type)

  useEffect(() => {
    setAmountExpenses(expenses?.amount)
    setCurrencyExpenses(expenses?.currency)
    setDateExpenses(expenses?.date)
    setDescriptionExpenses(expenses?.description)
    setPaymentTypeExpenses(expenses.payment_type)
    setQuantityExpenses(expenses?.quantity)
    setTypeExpenses(expenses.type)
  }, [currentEditingId])

  const [selectedPhoto, setSelectedPhoto] = useState<string[]>([])
  const [selectedPhotoForSend, setSelectedPhotoForSend] = useState<File[]>([])

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCurrencyExpenses(value)
  }

  const handlePhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    // Проверка на null перед использованием Array.from
    if (event.target.files) {
      const files = event.target.files
      const maxSize = 50 * 1024 * 1024 // 50MB в байтах

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > maxSize) {
          toast.error(
            `Файл ${files[i].name} превышает максимальный размер 50MB.`
          )
          return
        }
      }
      const filesArray: FileArray = Array.from(event.target.files)

      setSelectedPhotoForSend((prevPhotos: FileArray) => [
        ...prevPhotos,
        ...filesArray,
      ])

      const photosUrls: string[] = filesArray.map((file) =>
        URL.createObjectURL(file)
      )

      setSelectedPhoto((prevUrls: string[]) => [...prevUrls, ...photosUrls])
    }
  }

  const handleDeletePhoto = (photo: string) => {
    setSelectedPhoto((currentPhotos) =>
      currentPhotos.filter((e) => e !== photo)
    )

    const index = selectedPhoto.findIndex((e) => e === photo)

    setSelectedPhotoForSend((currentFiles) =>
      currentFiles.filter((_, i) => i !== index)
    )
  }
  const [isVisibleDeleteIconModal, setIsVisibleDeleteIconModal] =
    useState(false)

  const handleSaveExpenses = () => {
    const dataForSend = {
      id: expenses?.id,
      type: typeExpenses,
      payment_type: paymentTypeExpenses,
      date: dateExpenses,
      images: selectedPhotoForSend,
      quantity: quantityExpenses,
      amount: amountExpenses,
      currency: currencyExpenses,
      description: descriptionExpenses,
    }

    dispatch(fetchAddExpense(dataForSend)).then((res) => {
      //@ts-ignore
      if (!res.error) {
        setCurrentEditingId(null)
        setSelectedPhoto([])
        setSelectedPhotoForSend([])
      }
    })
  }

  const handleCloseEditExpenses = () => {
    setCurrentEditingId(null)
    setAmountExpenses(expenses?.amount)
    setCurrencyExpenses(expenses?.currency)
    setDateExpenses(expenses?.date)
    setDescriptionExpenses(expenses?.description)
    setPaymentTypeExpenses(expenses.payment_type)
    setQuantityExpenses(expenses?.quantity)
    setTypeExpenses(expenses.type)

    setSelectedPhoto([])
    setSelectedPhotoForSend([])
  }

  useLayoutEffect(() => {
    if (textareaRef.current) {
      //@ts-ignore
      textareaRef.current.style.height = 'inherit'
      //@ts-ignore
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [descriptionExpenses])

  return (
    <>
      {!isVisibleEditExpenses ? (
        <div
          className={classNames(
            styles.bxShadowSmall,
            styles.bgGray,
            styles.border10,
            styles.small2,
            styles.docItem,
            'pl-2 pr-2 mb-3'
          )}
        >
          <div className={classNames(styles.row, 'row')}>
            <div
              className={classNames(
                'col-5 col-sm-3 pr-2 pt-1 pb-1',
                styles.docDate,
                styles.small3
              )}
            >
              {expenses?.date}
            </div>
            <div className="col-4 col-sm-2 pr-2 pt-1 pb-1">
              <b>{TYPE_EXPENSES[(expenses?.type as ExpenseType) ?? 'other']}</b>
            </div>
            <div
              className={classNames(
                styles.nowrap,
                styles.small3,
                'col-3 col-sm-1 pr-2 pt-1 pb-1'
              )}
              style={{ textAlign: 'right' }}
            >
              {expenses?.quantity || ''}{' '}
              {expenses?.type === 'fuel' && expenses?.quantity ? 'л' : ''}
            </div>
            <div className="col col-sm-2 pt-1 pb-1">
              <b className={classNames(styles.nowrap, styles.colorRed)}>
                {expenses?.amount} {expenses?.currency}
              </b>
              &nbsp;&nbsp;{' '}
              <div className={classNames(styles.small3, styles.colorLightBlue)}>
                {typePayment[expenses?.payment_type]}
              </div>
            </div>
            <div
              className={classNames(styles.nowrap, 'col pt-1 pb-1')}
              style={{ textAlign: 'right' }}
            >
              <PhotoProvider
                className={styles.photoProvider}
                overlayRender={({ images, index, onClose }) => (
                  <>
                    <div
                      className={classNames(styles.btnDeleteIcon)}
                      onClick={() => {
                        setIsVisibleDeleteIconModal(true)
                      }}
                    />

                    {isVisibleDeleteIconModal && (
                      <div className={classNames(styles.deleteModal)}>
                        <h2 className={styles.deletePhotoTitle}>
                          Вы действительно хотите удалить фото?
                        </h2>
                        <div className={styles.deleteButtonWrapper}>
                          <button
                            onClick={() => {
                              dispatch(
                                fetchDeleteExpenseImage({
                                  //@ts-ignore
                                  imageId: expenses.images[index].id,
                                  currentExpensesId: expenses.id,
                                })
                              ).then(() => {
                                setIsVisibleDeleteIconModal(false)
                                onClose()
                                // if (currentCar) dispatch(fetchExpenses(currentCar.id))
                              })
                            }}
                            className={classNames(
                              styles.btn,
                              'd-block',
                              'd-sm-inline-block'
                            )}
                          >
                            Удалить
                          </button>
                          <button
                            onClick={() => setIsVisibleDeleteIconModal(false)}
                            className={classNames(
                              styles.btn,
                              styles.btnSmall,
                              styles.btnBlueOutline,
                              'd-block d-sm-inline-block'
                            )}
                          >
                            Отмена
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              >
                {expenses?.images?.map((photo: any, index: number) => {
                  return (
                    //@ts-ignore
                    <PhotoView src={photo?.image?.lg}>
                      {index === 0 ? (
                        <a
                          data-lightbox="note_01"
                          className={classNames(
                            styles.btn,
                            styles.btnSmall2,
                            styles.btnBlueOutline,
                            styles.picBtn,
                            'mr-1'
                          )}
                        >
                          <i
                            className={classNames(
                              styles.icon,
                              styles.icon15,
                              styles.iconPicture
                            )}
                          />
                          {expenses.images?.length}
                        </a>
                      ) : (
                        <img style={{ display: 'none' }} />
                      )}
                    </PhotoView>
                  )
                })}
              </PhotoProvider>
              <EditButton
                onClick={(e) => {
                  e.preventDefault()
                  handleEditClick(e)
                }}
              />

              <DeleteButton onClick={() => handleDeleteExpenses(expenses)} />
            </div>
            {expenses?.description && (
              <div
                className={classNames(
                  styles.small3,
                  styles.colorGray,
                  'col-12 p-1'
                )}
              >
                {expenses?.description}
              </div>
            )}
          </div>
          {/* <div>
      <a
        href="img/temp/photo-02.jpg"
        data-lightbox="note_money_1"
      ></a>
      <a
        href="img/temp/photo-03.jpg"
        data-lightbox="note_money_1"
      ></a>
    </div> */}
        </div>
      ) : (
        <div
          className={classNames(
            styles.bxShadowSmall,
            styles.bgGray,
            styles.small2,
            styles.border10,
            'pl-3 pr-3 mb-3'
          )}
        >
          <div className={classNames('row', styles.rowNote)}>
            <div className="col-7 col-sm-4 col-md-4 col-lg-4 col-xl-4  pl-1 pr-1 mb-2">
              <div
                className={styles.reportDate}
                id="report_date_input_wrapper2"
              >
                <DateTimePicker
                  id="money_date_input_wrapper"
                  selectedDate={dateExpenses}
                  setSelectedDate={setDateExpenses}
                  placeholder="Дата и время"
                />
              </div>
            </div>
            <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
              <select
                value={typeExpenses}
                onChange={(e) => setTypeExpenses(e.target.value)}
              >
                <option value="fuel">Топливо</option>
                <option value="tollRoad">Платная дорога</option>
                <option value="parking">Парковка</option>
                <option value="phone">Телефон</option>
                <option value="wash">Мойка</option>
                <option value="tire">Шиномонтаж</option>
                <option value="other">Иное</option>
              </select>
            </div>

            <div className="col-7 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-1 pr-1 mb-2">
              <input
                value={quantityExpenses || ''}
                onChange={(e) => {
                  if (onlyNumberRegularCheck(e)) {
                    //@ts-ignore
                    setQuantityExpenses(e.target.value.replace(',', '.'))
                  }
                }}
                type="text"
                placeholder="Кол-во"
              />
            </div>

            <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
              <select
                value={paymentTypeExpenses}
                onChange={(e) =>
                  //@ts-ignore

                  setPaymentTypeExpenses(e.target.value)
                }
              >
                <option value="cash">Нал</option>
                <option value="cash_check">Нал (чек)</option>
                <option value="card">Карта</option>
                <option value="card_check">Карта (чек)</option>
                <option value="fuel_card">Топл. карта</option>
              </select>
            </div>

            <div className="col-7 col-sm-4 col-md-4 col-lg-4 col-xl-4 pl-1 pr-1 mb-2">
              <input
                value={amountExpenses}
                //@ts-ignore

                onChange={(e) => {
                  if (onlyNumberRegularCheck(e)) {
                    //@ts-ignore
                    setAmountExpenses(e.target.value.replace(',', '.'))
                  }
                }}
                required
                type="text"
                placeholder="Сумма"
              />

              <div
                className={classNames(
                  styles.small3,
                  styles.radioTabs,
                  'd-flex flex-wrap justify-content-start'
                )}
                style={{ textAlign: 'left' }}
              >
                <div className="mt-1">
                  <input
                    name={`currency-${expenses.id}`}
                    id={`${expenses.id}BYN`}
                    type="radio"
                    value="BYN"
                    checked={currencyExpenses === 'BYN'}
                    onChange={handleCurrencyChange}
                  />
                  <label
                    className={styles.labelCurrency}
                    htmlFor={`${expenses.id}BYN`}
                  >
                    BYN
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    name={`currency-${expenses.id}`}
                    id={`${expenses.id}EUR`}
                    type="radio"
                    value="EUR"
                    checked={currencyExpenses === 'EUR'}
                    onChange={handleCurrencyChange}
                  />
                  <label
                    className={styles.labelCurrency}
                    htmlFor={`${expenses.id}EUR`}
                  >
                    EUR
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    name={`currency-${expenses.id}`}
                    id={`${expenses.id}RUB`}
                    type="radio"
                    value="RUB"
                    checked={currencyExpenses === 'RUB'}
                    onChange={handleCurrencyChange}
                  />
                  <label
                    className={styles.labelCurrency}
                    htmlFor={`${expenses.id}RUB`}
                  >
                    RUB
                  </label>
                </div>
              </div>
            </div>

            <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
              <div
                className={classNames(
                  styles.btn,
                  styles.btnBlueOutline,
                  styles.btnSmall2,
                  styles.btnWide,
                  styles.loadGallery
                )}
              >
                <b>+ Фото</b>
                <input
                  multiple={true}
                  onChange={(e) => handlePhotoChange(e)}
                  type="file"
                  name="report_gallery"
                  id="report_gallery"
                  accept={IMAGE_TYPE}
                />{' '}
              </div>
            </div>
            {!!selectedPhoto.length && (
              <div
                className={classNames(
                  'col-12 mt-3 mb-3',
                  styles.photoContainer
                )}
              >
                <PhotoProvider className={styles.photoProvider}>
                  {selectedPhoto?.map((photo) => (
                    <PhotoView src={photo}>
                      <div className={styles.photoView}>
                        <img src={photo} alt="" />
                        <span
                          onClick={(e) => {
                            e.stopPropagation()
                            handleDeletePhoto(photo)
                          }}
                          className={styles.del}
                        >
                          ×
                        </span>
                      </div>
                    </PhotoView>
                  ))}
                </PhotoProvider>
              </div>
            )}
            <div className="col-12 col-sm-12 col-md-12 mt-2 pl-1 pr-1">
              <small className={classNames(styles.small2, 'd-block')}>
                Пояснение:
              </small>
            </div>

            <div className="col-12 pl-1 pr-1">
              <textarea
                className={styles.noteText}
                ref={textareaRef}
                id="controlled-textarea"
                value={descriptionExpenses}
                onChange={(e) => setDescriptionExpenses(e.target.value)}
              />
            </div>

            <div className="col-8 pl-1 pr-1 mt-1">
              <button
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnBlue
                )}
                disabled={!amountExpenses || !dateExpenses}
                onClick={handleSaveExpenses}
              >
                <i
                  className={classNames(styles.icon, styles.iconCheckWhite)}
                ></i>{' '}
                Сохранить
              </button>
            </div>

            <div
              className="col-4 pl-1 pr-1 mt-1"
              style={{ textAlign: 'right' }}
            >
              <div
                onClick={() => handleCloseEditExpenses()}
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnBlueOutline
                )}
              >
                Отмена
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

type AllExpensesProps = {}
const AllExpenses: FC<AllExpensesProps> = ({}) => {
  const dispatch = useDispatch<AppDispatch>()
  const textareaRef = useRef(null)

  const { currentReport } = useSelector(reportsSelector)
  const isNewReport = !!currentReport

  const [selectedPhoto, setSelectedPhoto] = useState<string[]>([])
  const [selectedPhotoForSend, setSelectedPhotoForSend] = useState<File[]>([])
  const [isVisibleExpenses, setIsVisibleExpenses] = useState(false)
  const [amountExpenses, setAmountExpenses] = useState('')
  const [currencyExpenses, setCurrencyExpenses] = useState('BYN')
  const [dateExpenses, setDateExpenses] = useState('')
  const [descriptionExpenses, setDescriptionExpenses] = useState('')
  const [paymentTypeExpenses, setPaymentTypeExpenses] = useState('cash')
  const [quantityExpenses, setQuantityExpenses] = useState('')
  const [typeExpenses, setTypeExpenses] = useState('fuel')
  const [currentEditingId, setCurrentEditingId] = useState<number | null>(null)

  const handlePhotoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files) {
      const files = event.target.files
      const maxSize = 50 * 1024 * 1024

      for (let i = 0; i < files.length; i++) {
        if (files[i].size > maxSize) {
          toast.error(
            `Файл ${files[i].name} превышает максимальный размер 50MB.`
          )
          return
        }
      }
      const filesArray: FileArray = Array.from(event.target.files)

      setSelectedPhotoForSend((prevPhotos: FileArray) => [
        ...prevPhotos,
        ...filesArray,
      ])

      const photosUrls: string[] = filesArray.map((file) =>
        URL.createObjectURL(file)
      )

      setSelectedPhoto((prevUrls: string[]) => [...prevUrls, ...photosUrls])
    }
  }

  const handleDeletePhoto = (photo: string) => {
    setSelectedPhoto((currentPhotos) =>
      currentPhotos.filter((e) => e !== photo)
    )

    const index = selectedPhoto.findIndex((e) => e === photo)

    setSelectedPhotoForSend((currentFiles) =>
      currentFiles.filter((_, i) => i !== index)
    )
  }

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCurrencyExpenses(value)
  }

  const handleSaveExpenses = () => {
    const dataForSend = {
      type: typeExpenses,
      payment_type: paymentTypeExpenses,
      date: dateExpenses,
      images: selectedPhotoForSend,
      quantity: quantityExpenses,
      amount: amountExpenses,
      currency: currencyExpenses,
      description: descriptionExpenses,
    }

    dispatch(fetchAddExpense(dataForSend)).then((res) => {
      //@ts-ignore
      if (!res.error) {
        setAmountExpenses('')
        setCurrencyExpenses('BYN')
        setDateExpenses('')
        setDescriptionExpenses('')
        setPaymentTypeExpenses('cash')
        setQuantityExpenses('')
        setTypeExpenses('fuel')
        setSelectedPhoto([])
        setIsVisibleExpenses(false)
        setSelectedPhotoForSend([])
      }
    })
  }

  useLayoutEffect(() => {
    if (textareaRef.current) {
      //@ts-ignore
      textareaRef.current.style.height = 'inherit'
      //@ts-ignore
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [descriptionExpenses])

  return (
    <>
      <div>
        {isNewReport && (
          <div
            className={classNames(
              styles.paddingZero,
              'col-12 col-sm-12 mt-3 mb-2'
            )}
          >
            <button
              onClick={() => {
                setDateExpenses(
                  //@ts-ignore
                  moment(new Date(), 'YYYY-MM-DD HH:mm')
                )

                setCurrentEditingId(null)
                setIsVisibleExpenses(!isVisibleExpenses)
              }}
              className={classNames(
                styles.btn,
                styles.btnSmall2,
                styles.btnBlueOutline,
                styles.btnMarginMobile
              )}
            >
              <b>+ Добавить расход</b>
            </button>
          </div>
        )}
        <div>
          {isVisibleExpenses && (
            <div
              className={classNames(
                styles.bxShadowSmall,
                styles.bgGray,
                styles.small2,
                styles.border10,
                'pl-3 pr-3 mb-3'
              )}
            >
              <div className={classNames('row', styles.rowNote)}>
                <div className="col-7 col-sm-4 col-md-4 col-lg-4 col-xl-4  pl-1 pr-1 mb-2">
                  <div
                    className={styles.reportDate}
                    id="report_date_input_wrapper2"
                  >
                    <DateTimePicker
                      id="money_date_input_wrapper"
                      selectedDate={dateExpenses}
                      setSelectedDate={setDateExpenses}
                      placeholder="Дата и время"
                    />
                  </div>
                </div>
                <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
                  <select
                    value={typeExpenses}
                    onChange={(e) => setTypeExpenses(e.target.value)}
                  >
                    <option value="fuel">Топливо</option>
                    <option value="tollRoad">Платная дорога</option>
                    <option value="parking">Парковка</option>
                    <option value="phone">Телефон</option>
                    <option value="wash">Мойка</option>
                    <option value="tire">Шиномонтаж</option>
                    <option value="other">Иное</option>
                  </select>
                </div>

                <div className="col-7 col-sm-2 col-md-2 col-lg-2 col-xl-2 pl-1 pr-1 mb-2">
                  <input
                    value={quantityExpenses || ''}
                    onChange={(e) => {
                      if (onlyNumberRegularCheck(e)) {
                        setQuantityExpenses(e.target.value.replace(',', '.'))
                      }
                    }}
                    type="text"
                    placeholder="Кол-во"
                  />
                </div>

                <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
                  <select
                    value={paymentTypeExpenses}
                    onChange={(e) => setPaymentTypeExpenses(e.target.value)}
                  >
                    <option value="cash">Нал</option>
                    <option value="cash_check">Нал (чек)</option>
                    <option value="card">Карта</option>
                    <option value="card_check">Карта (чек)</option>
                    <option value="fuel_card">Топл. карта</option>
                  </select>
                </div>

                <div className="col-7 col-sm-4 col-md-4 col-lg-4 col-xl-4 pl-1 pr-1 mb-2">
                  <input
                    value={amountExpenses}
                    onChange={(e) => {
                      if (onlyNumberRegularCheck(e)) {
                        setAmountExpenses(e.target.value.replace(',', '.'))
                      }
                    }}
                    required
                    type="text"
                    placeholder="Сумма"
                  />

                  <div
                    className={classNames(
                      styles.small3,
                      styles.radioTabs,
                      'd-flex flex-wrap justify-content-start'
                    )}
                    style={{ textAlign: 'left' }}
                  >
                    <div className="mt-1">
                      <input
                        name="currency"
                        id="currency_radio6"
                        type="radio"
                        value="BYN"
                        checked={currencyExpenses === 'BYN'}
                        onChange={handleCurrencyChange}
                      />
                      <label
                        className={styles.labelCurrency}
                        htmlFor="currency_radio6"
                      >
                        BYN
                      </label>
                    </div>
                    <div className="mt-1">
                      <input
                        name="currency"
                        id="currency_radio4"
                        type="radio"
                        value="EUR"
                        checked={currencyExpenses === 'EUR'}
                        onChange={handleCurrencyChange}
                      />
                      <label
                        className={styles.labelCurrency}
                        htmlFor="currency_radio4"
                      >
                        EUR
                      </label>
                    </div>
                    <div className="mt-1">
                      <input
                        name="currency"
                        id="currency_radio5"
                        type="radio"
                        value="RUB"
                        checked={currencyExpenses === 'RUB'}
                        onChange={handleCurrencyChange}
                      />
                      <label
                        className={styles.labelCurrency}
                        htmlFor="currency_radio5"
                      >
                        RUB
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-5 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
                  <div
                    className={classNames(
                      styles.btn,
                      styles.btnBlueOutline,
                      styles.btnSmall2,
                      styles.btnWide,
                      styles.loadGallery
                    )}
                  >
                    <b>+ Фото</b>
                    <input
                      multiple={true}
                      onChange={(e) => handlePhotoChange(e)}
                      type="file"
                      name="report_gallery"
                      id="report_gallery"
                      accept={IMAGE_TYPE}
                    />{' '}
                  </div>
                </div>
                {!!selectedPhoto.length && (
                  <div
                    className={classNames(
                      'col-12 mt-3 mb-3',
                      styles.photoContainer
                    )}
                  >
                    <PhotoProvider className={styles.photoProvider}>
                      {selectedPhoto?.map((photo: string) => (
                        <PhotoView src={photo}>
                          <div className={styles.photoView}>
                            <img src={photo} alt="" />
                            <span
                              onClick={(e) => {
                                e.stopPropagation()
                                handleDeletePhoto(photo)
                              }}
                              className={styles.del}
                            >
                              ×
                            </span>
                          </div>
                        </PhotoView>
                      ))}
                    </PhotoProvider>
                  </div>
                )}
                <div className="col-12 col-sm-12 col-md-12 mt-2 pl-1 pr-1">
                  <small className={classNames(styles.small2, 'd-block')}>
                    Пояснение:
                  </small>
                </div>

                <div className="col-12 pl-1 pr-1">
                  <textarea
                    className={styles.noteText}
                    ref={textareaRef}
                    id="controlled-textarea"
                    value={descriptionExpenses}
                    onChange={(e) => setDescriptionExpenses(e.target.value)}
                  />
                </div>

                <div className="col-8 pl-1 pr-1 mt-1">
                  <button
                    className={classNames(
                      styles.btn,
                      styles.btnSmall2,
                      styles.btnBlue
                    )}
                    onClick={(e) => {
                      e.preventDefault()
                      handleSaveExpenses()
                    }}
                    disabled={!amountExpenses || !dateExpenses}
                  >
                    <i
                      className={classNames(styles.icon, styles.iconCheckWhite)}
                    ></i>{' '}
                    Сохранить
                  </button>
                </div>

                <div
                  className="col-4 pl-1 pr-1 mt-1"
                  style={{ textAlign: 'right' }}
                >
                  <div
                    onClick={() => setIsVisibleExpenses(false)}
                    className={classNames(
                      styles.btn,
                      styles.btnSmall2,
                      styles.btnBlueOutline
                    )}
                  >
                    Отмена
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentReport?.expenses &&
            currentReport.expenses.map((item: any) => (
              <Expenses
                key={item.id}
                expenses={item}
                currentEditingId={currentEditingId}
                setCurrentEditingId={setCurrentEditingId}
                setIsVisibleExpenses={setIsVisibleExpenses}
              />
            ))}
        </div>
      </div>
    </>
  )
}
export default AllExpenses
