import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'

import styles from '../ReportModal.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../../redux/store'
import { Expense, fetchAddIncoming } from '../../../../redux/reducers/reports'
import classNames from 'classnames'
import { reportsSelector } from '../../../../redux/selectors/reports-selector'

import DateTimePicker from '../../../common/date-picker/DatePicker'
import {
  setCurrentIncoming,
  setVisibleDeleteModalIncoming,
} from '../../../../redux/reducers/reports'
import EditButton from '../../../buttons/actions-button/EditButton'
import DeleteButton from '../../../buttons/actions-button/DeleteButton'
import { onlyNumberRegularCheck } from '../../../../utils/onlyNumberRegularCheck'

import moment from 'moment'
import { PATH } from '../../../../constants/path'
type ExpenseType =
  | 'fuel'
  | 'tollRoad'
  | 'parking'
  | 'phone'
  | 'wash'
  | 'tire'
  | 'other'

const TYPE_EXPENSES: Record<ExpenseType, string> = {
  fuel: 'Топливо',
  tollRoad: 'Платная дорога',
  parking: 'Парковка',
  phone: 'Телефон',
  wash: 'Мойка',
  tire: 'Шиномонтаж',
  other: 'Иное',
}

type FileArray = File[]

const typePayment = {
  cash: 'Нал',
  cash_check: 'Нал (чек)',
  card: 'Карта',
  card_check: 'Карта (чек)',
  fuel_card: 'Топл. карта',
}
function formatNumber(number: number) {
  let roundedNumber = number.toFixed(2)
  if (roundedNumber.endsWith('.00')) {
    return Math.floor(number).toString()
  } else {
    return roundedNumber
  }
}
const Incoming: FC<{
  incoming: Expense
  currentEditingId: number | null
  setCurrentEditingId: (id: number | null) => void
  setIsVisibleIncomings: (value: boolean) => void
}> = ({
  incoming,
  currentEditingId,
  setCurrentEditingId,
  setIsVisibleIncomings,
}) => {
  const isVisibleEditIncoming = currentEditingId === incoming.id
  const dispatch = useDispatch<AppDispatch>()
  const handleDeleteIncoming = (incoming: Expense) => {
    dispatch(setCurrentIncoming(incoming))
    dispatch(setVisibleDeleteModalIncoming(true))
  }

  const handleEditClick = (e: any) => {
    if (isVisibleEditIncoming) {
      setCurrentEditingId(null)
    } else {
      if (incoming?.id) setCurrentEditingId(incoming.id)
      setIsVisibleIncomings(false)
    }
  }
  const textareaRef = useRef(null)

  const [amountIncoming, setAmountIncoming] = useState(incoming?.amount)
  const [currency, setCurrency] = useState(incoming?.currency)
  const [dateIncoming, setDateIncoming] = useState(incoming?.date || '')
  const [descriptionIncoming, setDescriptionIncoming] = useState(
    incoming?.description
  )

  useEffect(() => {
    setAmountIncoming(incoming?.amount)
    setCurrency(incoming?.currency)
    setDateIncoming(incoming?.date)
    setDescriptionIncoming(incoming?.description)
  }, [currentEditingId])

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCurrency(value)
  }

  const handleSaveIncoming = () => {
    const dataForSend = {
      id: incoming?.id,
      date: dateIncoming,
      amount: amountIncoming,
      currency: currency,
      description: descriptionIncoming,
    }

    dispatch(fetchAddIncoming(dataForSend)).then((res) => {
      //@ts-ignore
      if (!res.error) {
        setCurrentEditingId(null)
      }
    })
  }

  const handleCloseEditIncoming = () => {
    setCurrentEditingId(null)
    setAmountIncoming(incoming?.amount)
    setCurrency(incoming?.currency)
    setDateIncoming(incoming?.date)
    setDescriptionIncoming(incoming?.description)
  }

  useLayoutEffect(() => {
    if (textareaRef.current) {
      //@ts-ignore
      textareaRef.current.style.height = 'inherit'
      //@ts-ignore
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [descriptionIncoming])

  return (
    <>
      {!isVisibleEditIncoming ? (
        <div
          className={classNames(
            styles.bxShadowSmall,
            styles.bgGray,
            styles.border10,
            styles.small2,
            styles.docItem,
            'pl-2 pr-2 mb-3'
          )}
        >
          <div className={classNames(styles.specialPadding, 'row')}>
            <div
              className={classNames(
                'col-5 col-sm-3 pr-2 pt-1 pb-1',
                styles.docDate,
                styles.small3
              )}
            >
              {incoming.date}
            </div>
            <div className="col col-sm-2 pt-1 pb-1">
              <b className={classNames(styles.nowrap, styles.colorRed)}>
                {incoming?.amount} {incoming?.currency}
              </b>
            </div>
            <div
              className={classNames(
                styles.small2,
                styles.refDescription,
                'col-9 col-sm-10 pt-1 pb-1'
              )}
            >
              {incoming?.description}
            </div>
            <div
              className={classNames(
                styles.docButtons,
                styles.nowrap,
                'col-3 col-sm-2 pt-1 pb-1'
              )}
              style={{ textAlign: 'right' }}
            >
              <EditButton
                onClick={(e) => {
                  e.preventDefault()
                  handleEditClick(e)
                }}
              />

              <DeleteButton onClick={() => handleDeleteIncoming(incoming)} />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            styles.bxShadowSmall,
            styles.bgGray,
            styles.small2,
            styles.border10,
            'pl-3 pr-3 mb-3'
          )}
        >
          <div className={classNames('row', styles.rowNote)}>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4  pl-1 pr-1 mb-2">
              <small className={classNames(styles.small2, 'd-block mb-1')}>
                Дата и время
              </small>
              <div
                className="report-date"
                id="report_date_input_wrapper_income"
              >
                <DateTimePicker
                  id="money_date_input_wrapper"
                  selectedDate={dateIncoming}
                  setSelectedDate={setDateIncoming}
                  placeholder="Дата и время"
                />
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
              <small className={classNames(styles.small2, 'd-block mb-1')}>
                Сумма
              </small>
              <input
                value={amountIncoming}
                //@ts-ignore

                onChange={(e) => {
                  if (onlyNumberRegularCheck(e)) {
                    //@ts-ignore
                    setAmountIncoming(e.target.value.replace(',', '.'))
                  }
                }}
                required
                type="text"
                placeholder="Сумма"
              />

              <div
                className={classNames(
                  styles.small3,
                  styles.radioTabs,
                  'd-flex flex-wrap justify-content-start'
                )}
                style={{ textAlign: 'left' }}
              >
                <div className="mt-1">
                  <input
                    name="currency"
                    id="currency_radio6"
                    type="radio"
                    value="BYN"
                    checked={currency === 'BYN'}
                    onChange={handleCurrencyChange}
                  />
                  <label
                    className={styles.labelCurrency}
                    htmlFor="currency_radio6"
                  >
                    BYN
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    name="currency"
                    id="currency_radio4"
                    type="radio"
                    value="EUR"
                    checked={currency === 'EUR'}
                    onChange={handleCurrencyChange}
                  />
                  <label
                    className={styles.labelCurrency}
                    htmlFor="currency_radio4"
                  >
                    EUR
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    name="currency"
                    id="currency_radio5"
                    type="radio"
                    value="RUB"
                    checked={currency === 'RUB'}
                    onChange={handleCurrencyChange}
                  />
                  <label
                    className={styles.labelCurrency}
                    htmlFor="currency_radio5"
                  >
                    RUB
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-5 col-xl-5 pl-1 pr-1 mb-2">
              <small className={classNames(styles.small2, 'd-block mb-1')}>
                От кого, за что:
              </small>
              <textarea
                style={{ padding: '12px' }}
                ref={textareaRef}
                id="controlled-textarea"
                value={descriptionIncoming}
                onChange={(e) => setDescriptionIncoming(e.target.value)}
              />
            </div>

            <div className="col-8 pl-1 pr-1 mt-1">
              <button
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnBlue
                )}
                disabled={!amountIncoming || !dateIncoming}
                onClick={handleSaveIncoming}
              >
                <i
                  className={classNames(styles.icon, styles.iconCheckWhite)}
                ></i>{' '}
                Сохранить
              </button>
            </div>
            <div
              className="col-4 pl-1 pr-1 mt-1"
              style={{ textAlign: 'right' }}
            >
              <div
                onClick={() => handleCloseEditIncoming()}
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnBlueOutline
                )}
              >
                Отмена
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

type AllIncomingsProps = any
const AllIncomings: FC<AllIncomingsProps> = ({
  issuedByn,
  issuedRub,
  issuedEur,
  issuedBynBalance,
  issuedRubBalance,
  issuedEurBalance,
  setIssuedRub,
  setReimbursementDate,
  reimbursementDate,
  setIssuedEur,
  setIssuedByn,
}) => {
  const dispatch = useDispatch<AppDispatch>()
  const textareaRef = useRef(null)

  const { currentReport, currentReportId } = useSelector(reportsSelector)
  const isNewReport = !!currentReport

  const [isVisibleIncomings, setIsVisibleIncomings] = useState(false)
  const [amountIncoming, setAmountIncoming] = useState('')
  const [currency, setCurrency] = useState('BYN')
  const [dateIncomings, setDateIncomings] = useState('')
  const [descriptionIncoming, setDescriptionIncoming] = useState('')

  const [currentEditingId, setCurrentEditingId] = useState<number | null>(null)

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCurrency(value)
  }

  const handleSaveIncoming = () => {
    const dataForSend = {
      date: dateIncomings,
      amount: amountIncoming,
      currency: currency,
      description: descriptionIncoming,
    }

    dispatch(fetchAddIncoming(dataForSend)).then((res) => {
      //@ts-ignore
      if (!res.error) {
        setAmountIncoming('')
        setCurrency('BYN')
        setDateIncomings('')
        setDescriptionIncoming('')
        setIsVisibleIncomings(false)
      }
    })
  }

  useLayoutEffect(() => {
    if (textareaRef.current) {
      //@ts-ignore
      textareaRef.current.style.height = 'inherit'
      //@ts-ignore
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [descriptionIncoming])

  const isEditMyReport = window.location.pathname === PATH.MY_REPORTS

  return (
    <>
      {currentReportId && (
        <>
          <div className="col-12 mt-3 mb-4">
            <div className="mb-2">
              <b>Выдано в рейс</b>
            </div>
            <div className="row">
              {(issuedByn || issuedBynBalance) && (
                <div className={classNames(styles.small, 'col-4 pr-0 small')}>
                  <div className="mb-1">
                    <b>{issuedByn || 0}</b>{' '}
                    <span
                      className={classNames(styles.small3, styles.colorGray)}
                    >
                      BYN
                    </span>
                  </div>
                  <div className={classNames(styles.small3)}>
                    Ост.{' '}
                    <b className={styles.colorRed}>
                      {formatNumber(+issuedBynBalance) || 0}
                    </b>{' '}
                    <span
                      className={classNames(styles.small3, styles.colorRed)}
                    >
                      BYN
                    </span>
                  </div>
                </div>
              )}

              {(issuedRub || issuedRubBalance) && (
                <div className={classNames(styles.small, 'col-4 pr-0 small')}>
                  <div className="mb-1">
                    <b>{issuedRub || 0}</b>{' '}
                    <span
                      className={classNames(styles.small3, styles.colorGray)}
                    >
                      RUB
                    </span>
                  </div>
                  <div className={classNames(styles.small3)}>
                    Ост.{' '}
                    <b className={styles.colorRed}>
                      {formatNumber(+issuedRubBalance) || 0}
                    </b>{' '}
                    <span
                      className={classNames(styles.small3, styles.colorRed)}
                    >
                      RUB
                    </span>
                  </div>
                </div>
              )}

              {(issuedEur || issuedEurBalance) && (
                <div className={classNames(styles.small, 'col-4 pr-0 small')}>
                  <div className="mb-1">
                    <b>{issuedEur || 0}</b>{' '}
                    <span
                      className={classNames(styles.small3, styles.colorGray)}
                    >
                      EUR
                    </span>
                  </div>
                  <div className={classNames(styles.small3)}>
                    Ост.{' '}
                    <b className={styles.colorRed}>
                      {formatNumber(+issuedEurBalance) || 0}
                    </b>{' '}
                    <span
                      className={classNames(styles.small3, styles.colorRed)}
                    >
                      EUR
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div
        className={classNames(styles.paddingZero, 'col-12 col-sm-12 mt-3 mb-2')}
      >
        <button
          onClick={() => {
            setDateIncomings(
              //@ts-ignore
              moment(new Date(), 'YYYY-MM-DD HH:mm')
            )

            setCurrentEditingId(null)
            setIsVisibleIncomings(!isVisibleIncomings)
          }}
          className={classNames(
            styles.btn,
            styles.btnSmall2,
            styles.btnBlueOutline,
            styles.btnMarginMobile
          )}
        >
          <b>+ Добавить приход</b>
        </button>
      </div>
      {isVisibleIncomings && (
        <div
          className={classNames(
            styles.bxShadowSmall,
            styles.bgGray,
            styles.small2,
            styles.border10,
            'pl-3 pr-3 mb-3'
          )}
        >
          <div className={classNames('row', styles.rowNote)}>
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4  pl-1 pr-1 mb-2">
              <small className={classNames(styles.small2, 'd-block mb-1')}>
                Дата и время
              </small>
              <div
                className="report-date"
                id="report_date_input_wrapper_income"
              >
                <DateTimePicker
                  id="money_date_input_wrapper"
                  selectedDate={dateIncomings}
                  setSelectedDate={setDateIncomings}
                  placeholder="Дата и время"
                />
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 pl-1 pr-1 mb-2">
              <small className={classNames(styles.small2, 'd-block mb-1')}>
                Сумма
              </small>
              <input
                value={amountIncoming}
                //@ts-ignore

                onChange={(e) => {
                  if (onlyNumberRegularCheck(e)) {
                    //@ts-ignore
                    setAmountIncoming(e.target.value.replace(',', '.'))
                  }
                }}
                required
                type="text"
                placeholder="Сумма"
              />

              <div
                className={classNames(
                  styles.small3,
                  styles.radioTabs,
                  'd-flex flex-wrap justify-content-start'
                )}
                style={{ textAlign: 'left' }}
              >
                <div className="mt-1">
                  <input
                    name="currency"
                    id="currency_radio6"
                    type="radio"
                    value="BYN"
                    checked={currency === 'BYN'}
                    onChange={handleCurrencyChange}
                  />
                  <label
                    className={styles.labelCurrency}
                    htmlFor="currency_radio6"
                  >
                    BYN
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    name="currency"
                    id="currency_radio4"
                    type="radio"
                    value="EUR"
                    checked={currency === 'EUR'}
                    onChange={handleCurrencyChange}
                  />
                  <label
                    className={styles.labelCurrency}
                    htmlFor="currency_radio4"
                  >
                    EUR
                  </label>
                </div>
                <div className="mt-1">
                  <input
                    name="currency"
                    id="currency_radio5"
                    type="radio"
                    value="RUB"
                    checked={currency === 'RUB'}
                    onChange={handleCurrencyChange}
                  />
                  <label
                    className={styles.labelCurrency}
                    htmlFor="currency_radio5"
                  >
                    RUB
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-5 col-xl-5 pl-1 pr-1 mb-2">
              <small className={classNames(styles.small2, 'd-block mb-1')}>
                От кого, за что:
              </small>
              <textarea
                style={{ padding: '12px' }}
                ref={textareaRef}
                id="controlled-textarea"
                value={descriptionIncoming}
                onChange={(e) => setDescriptionIncoming(e.target.value)}
              />
            </div>

            <div className="col-8 pl-1 pr-1 mt-1">
              <button
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnBlue
                )}
                disabled={!amountIncoming || !dateIncomings}
                onClick={handleSaveIncoming}
              >
                <i
                  className={classNames(styles.icon, styles.iconCheckWhite)}
                ></i>{' '}
                Сохранить
              </button>
            </div>
            <div
              className="col-4 pl-1 pr-1 mt-1"
              style={{ textAlign: 'right' }}
            >
              <div
                onClick={() => setIsVisibleIncomings(false)}
                className={classNames(
                  styles.btn,
                  styles.btnSmall2,
                  styles.btnBlueOutline
                )}
              >
                Отмена
              </div>{' '}
            </div>
          </div>
        </div>
      )}

      {currentReport?.incomings &&
        currentReport.incomings.map((item: any) => (
          <Incoming
            key={item.id}
            incoming={item}
            currentEditingId={currentEditingId}
            setCurrentEditingId={setCurrentEditingId}
            setIsVisibleIncomings={setIsVisibleIncomings}
          />
        ))}
    </>
  )
}
export default AllIncomings
