import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { MyKnownError } from '../types/common'
import axiosInstance from '../../utils/axiosInstance'
import { URL } from '../../constants/url'
import { toast } from 'react-toastify'
import { RootState } from '../store'
import { setCurrentClientPhone } from './clients'

// Определение типа для элемента массива phones
export interface Phone {
  id: number
  number: string
  name: string
  job: string
  email?: string
  notification: boolean
}

// Начальное состояние
interface PhonesState {
  phones: Phone[] | []
  isLoading: boolean
  error: string | null
  // РЕДАКТИРОВАНИЕ и ДОБАВЛЕНИЕ
  isPendingAddPhone: boolean
  isFulfilledAddPhone: boolean
  isRejectAddPhone: boolean
  isVisibleDeleteModalPhone: boolean
  currentPhone: Phone | null
}

const initialState: PhonesState = {
  phones: [],
  isLoading: false,
  error: null,
  // РЕДАКТИРОВАНИЕ и ДОБАВЛЕНИЕ
  isPendingAddPhone: false,
  isFulfilledAddPhone: false,
  isRejectAddPhone: false,
  isVisibleDeleteModalPhone: false,
  currentPhone: null,
}

export const fetchPhones = createAsyncThunk(
  'phones/fetchPhones',
  async (id: number | string, thunkAPI) => {
    try {
      const response: AxiosResponse = await axiosInstance.get(
        `/${URL.clients}/${id}/phones`
      )

      if (response.statusText === 'OK') {
        return response.data
      }
      throw new Error()
    } catch (error) {
      const typedError = error as MyKnownError

      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

export const fetchDeletePhones = createAsyncThunk(
  'phones/fetchDeletePhones',
  async (_, thunkAPI) => {
    const {
      clients: { currentClientByRequest },
      phones: { currentPhone },
    } = thunkAPI.getState() as RootState

    try {
      const response = await axiosInstance.delete(
        `/${URL.clients}/${currentClientByRequest && currentClientByRequest.id}/phones/${currentPhone?.id}`
      )

      if (
        response.statusText === 'OK' ||
        response.statusText === 'Created' ||
        String(response.status) === '204'
      ) {
        return { ...response.data, id: currentPhone?.id }
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

type FetchAddPhone = {
  id?: string | number
  number: string
  notification: boolean
  name: string
  job: string
  email?: string
}

export const fetchAddPhones = createAsyncThunk(
  'phones/fetchAddPhones',
  async (
    { number, notification, name, job, id, email = '' }: FetchAddPhone,
    thunkAPI
  ) => {
    const {
      clients: { currentClientByRequest },
    } = thunkAPI.getState() as RootState

    const formData = new FormData()

    if (number) formData.append('number', number)
    formData.append('name', name)
    formData.append('job', job)
    formData.append('email', email)

    formData.append('notification', notification ? '1' : '0')

    if (id) {
      formData.set('_method', 'put')
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.clients}/${currentClientByRequest && currentClientByRequest.id}/phones${id ? `/${id}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        thunkAPI.dispatch(setCurrentClientPhone(response.data))

        return response.data
      }
      throw new Error('Не удалось создать заметку')
    } catch (error) {
      const typedError = error as MyKnownError
      return thunkAPI.rejectWithValue(
        typedError?.response?.data?.message || 'Ошибка при отправке данных'
      )
    }
  }
)

const phonesSlice = createSlice({
  name: 'phones',
  initialState,
  reducers: {
    clearPhones: (state) => {
      return { ...state, phones: [] }
    },

    setCurrentPhone: (state, action) => {
      return { ...state, currentPhone: action.payload }
    },

    setVisibleDeleteModalPhone: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteModalPhone: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPhones.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(
        fetchPhones.fulfilled,
        (state, action: PayloadAction<Phone[]>) => {
          state.phones = action.payload
          state.isLoading = false
        }
      )
      .addCase(fetchPhones.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.payload as string
      })
      .addCase(fetchDeletePhones.pending, (state) => {
        return state
      })
      .addCase(fetchDeletePhones.fulfilled, (state, action) => {
        toast.success('Удалено!')

        return {
          ...state,
          phones: state.phones.filter((item) => item.id !== action.payload.id),
          isVisibleDeleteModalPhone: false,
        }
      })
      .addCase(fetchDeletePhones.rejected, (state, action) => {
        toast.error(action.payload as string)

        return state
      })
      .addCase(fetchAddPhones.pending, (state) => {
        return { ...state, isPendingAddPhone: true }
      })
      .addCase(fetchAddPhones.fulfilled, (state, action) => {
        const isExist = state.phones.find(
          (phone) => phone.id === action.payload.id
        )

        if (isExist) {
          return {
            ...state,
            isPendingAddPhone: false,
            phones: state.phones.map((phone) =>
              phone.id === action.payload.id ? action.payload : phone
            ),
          }
        } else {
          return {
            ...state,
            isPendingAddPhone: false,
            phones: [action.payload, ...state.phones],
          }
        }
      })
      .addCase(fetchAddPhones.rejected, (state, action) => {
        toast.error(action.payload as string)
        return { ...state, isPendingAddPhone: false }
      })
  },
})

export const { clearPhones, setVisibleDeleteModalPhone, setCurrentPhone } =
  phonesSlice.actions

export default phonesSlice.reducer
