export const PATH = {
  DASHBOARD: '/',
  AUTH: '/auth',
  CARS: '/cars',
  COMPANIES: '/companies',
  REPORTS: '/reports',
  REPORTS_COMMON: 'reports-common',
  MY_REPORTS: '/my-reports',
  EMPLOYEES: '/employees',
  PROFILE: '/profile',
  RESET_PASSWORD: '/pass_recovery',
  DOCUMENTS: '/documents',
  CLIENTS: '/clients',
  WAYBILLS: '/waybills',
  SETTINGS: '/settings',
  PARTS: '/parts',
} as const
