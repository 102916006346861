import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from '@reduxjs/toolkit'
import { URL } from '../../constants/url'
import { AxiosError, AxiosResponse } from 'axios'
import axiosInstance from '../../utils/axiosInstance'
import { RootState } from '../store'
import { toast } from 'react-toastify'
import { getRole } from '../../utils/getRole'
import { Expense } from './reports'

export interface ReportCommon {
  arrival: string
  departure: string
  expense_commons: Array<any>
  id: number
  number: string
  reimbursement_date: string
  report_images_url: null
  report_pdf_url: string
  report_pdf_advance_url: string
  status: string
  all_expenses: string
}

export interface InitState {
  currentReportTab: string
  reportsCommon: ReportCommon[]
  isPendingGetReportsCommon: boolean
  isPendingGetCurrentReports: boolean
  currentReportCommon: ReportCommon | null
  isVisibleModalReportCommon: boolean
  isVisibleDeleteModalReportCommon: boolean
  currentExpensesCommon: Expense | null
  isPendingGetCurrentReportsCommon: boolean

  lastReportsQueryCommon: any
  currentReportId: number | string | null

  isPendingAddExpenseCommon: boolean
  isRejectAddExpenseCommon: boolean

  isVisibleDeleteModalExpensesCommon: boolean
  isFulfilledDeleteExpenseCommon: boolean
  isPendingDeleteExpenseCommon: boolean
  isRejectDeleteExpenseCommon: boolean
}

const initialState: InitState = {
  currentReportTab: '1',
  reportsCommon: [],
  currentExpensesCommon: null,
  isPendingGetReportsCommon: false,
  currentReportCommon: null,
  isVisibleModalReportCommon: false,
  isVisibleDeleteModalReportCommon: false,
  lastReportsQueryCommon: [],

  isPendingAddExpenseCommon: false,
  isRejectAddExpenseCommon: false,
  isPendingGetCurrentReportsCommon: false,
  isVisibleDeleteModalExpensesCommon: false,
  isFulfilledDeleteExpenseCommon: false,
  isPendingDeleteExpenseCommon: false,
  isRejectDeleteExpenseCommon: false,

  isPendingGetCurrentReports: false,
  currentReportId: null,
}

interface ReportsQuery {
  dateFrom?: Date | string
  dateTo?: Date | string
}

export const fetchReportsCommon = createAsyncThunk(
  'reportsCommon/fetchReportsCommon',
  async ({ dateFrom, dateTo }: any, { rejectWithValue }) => {
    const params = new URLSearchParams()

    const formatDate = (date: Date | string) => {
      const newDate = new Date(date)
      const formattedDate = newDate
        .toLocaleDateString('ru-RU', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          timeZone: 'Europe/Moscow',
        })
        .split('.')
        .reverse()
        .join('-')
      return formattedDate
    }

    // @ts-ignore
    const formattedDateFrom = formatDate(dateFrom)
    //@ts-ignore
    const formattedDateTo = formatDate(dateTo)

    if (dateFrom) params.append('date_from', formattedDateFrom)
    if (dateTo) params.append('date_to', formattedDateTo)
    params.append('order_direction', 'desc')
    try {
      const response = await axiosInstance.get(`/${URL.reportCommons}`, {
        params,
      })

      if (response.statusText === 'OK') {
        return response.data
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error)
        return rejectWithValue(error.response?.data)
      } else {
        console.error('An unexpected error occurred:', error)
        return rejectWithValue('An unexpected error occurred')
      }
    }
  }
)

const sortByDate = (arr: ReportCommon[]) => {
  return Boolean(arr.length)
    ? arr.sort((a, b) => {
        return new Date(b.departure).getTime() - new Date(a.departure).getTime()
      })
    : arr
}

export const fetchCurrentReportCommon = createAsyncThunk(
  'reports/fetchCurrentReportCommon',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/${URL.reportCommons}/${id}`)

      if (response.statusText === 'OK') {
        return response.data
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error)
        return rejectWithValue(error.response?.data)
      } else {
        console.error('An unexpected error occurred:', error)
        return rejectWithValue('An unexpected error occurred')
      }
    }
  }
)

export const fetchAddReportCommon = createAsyncThunk(
  'reports/fetchAddReportCommon',
  async (
    { number, status, departure, arrival, reimbursementDate, id }: any,
    { rejectWithValue }
  ) => {
    const item = localStorage.getItem('userInfo')
    const userInfo = item ? JSON.parse(item) : {}

    const formData = new FormData()
    formData.append('departure', departure)

    if (arrival) formData.append('arrival', arrival)
    if (number) formData.append('number', number)
    if (status) formData.append('status', status)
    formData.append('user_id', userInfo.id)

    if (reimbursementDate)
      formData.append('reimbursement_date', reimbursementDate)

    if (id) {
      formData.set('_method', 'put')
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.reportCommons}${id ? `/${id}` : ''}`,
        formData
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      } else {
        throw new Error(response.statusText)
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error)
        return rejectWithValue(error.response?.data.message)
      } else {
        console.error('An unexpected error occurred:', error)
        return rejectWithValue('An unexpected error occurred')
      }
    }
  }
)

export const fetchReportFileByType = createAsyncThunk(
  'reports/fetchReportFile',
  async (
    { dateFrom, dateTo, exportFormat, getCars, getEmployees }: any,

    { rejectWithValue, getState }
  ) => {
    try {
      const params = new URLSearchParams()

      params.append('date_from', dateFrom)
      params.append('date_to', dateTo)
      params.append('export', exportFormat)

      getCars.forEach((car: string) => {
        params.append('cars[]', car)
      })

      getEmployees.forEach((employee: string) => {
        params.append('employees[]', employee)
      })
      let response

      response = await axiosInstance.get(
        `/${URL.reportCommons}/${exportFormat}`,
        {
          params,
          responseType: 'blob',
        }
      )

      if (response.statusText === 'OK' || response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `report.${exportFormat}`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
        return response.data
      }

      throw new Error('Ошибка при получении файла')
    } catch (error) {
      console.error('Ошибка при получении файла:', error)
      return rejectWithValue('Ошибка при получении файла')
    }
  }
)

export const fetchReportFile = createAsyncThunk(
  'reports/fetchReportFile',
  async (id: number, { rejectWithValue, getState }) => {
    try {
      const {
        reports: { currentReportId },
      } = getState() as RootState

      if (!currentReportId) {
        throw new Error('Отчет не найден')
      }
      const response = await axiosInstance.get(
        `/${URL.reportCommons}/${id || currentReportId}/pdf`,
        {
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        }
      )

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        window.open(url, '_blank')
        return response.data
      } else {
        throw new Error('Ошибка при получении файла')
      }
    } catch (error: any) {
      console.error('Ошибка при получении файла:', error)
      if (error?.response) {
        console.error('Response data:', error.response.data)
        console.error('Response status:', error.response.status)
        console.error('Response headers:', error.response.headers)
      } else if (error.request) {
        console.error('Request data:', error.request)
      } else {
        console.error('Error message:', error.message)
      }
      return rejectWithValue('Ошибка при получении файла')
    }
  }
)

export const fetchDeleteReportCommon = createAsyncThunk(
  'reports/fetchDeleteReportCommon',
  async (_, thunkAPI) => {
    const {
      reportsCommon: { currentReportCommon },
    } = thunkAPI.getState() as RootState

    try {
      const response = await axiosInstance.delete(
        `/${URL.reportCommons}/${currentReportCommon?.id}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        return response.data
      }
      throw new Error('Не удалось отправить данные')
    } catch (error) {
      console.error('Ошибка при отправке данных:', error)
      return thunkAPI.rejectWithValue('Ошибка при отправке данных')
    }
  }
)

//EXPENSES
export const fetchAddExpenseCommon = createAsyncThunk(
  'expenses/fetchAddExpenseCommon',
  async (
    {
      type,
      payment_type,
      date,
      quantity,
      amount,
      currency,
      description,
      id,
      images,
    }: any,
    thunkAPI
  ) => {
    const {
      reportsCommon: { currentReportCommon, isPendingAddExpenseCommon },
    } = thunkAPI.getState() as RootState

    const formData = new FormData()

    formData.append('type', type)
    formData.append('payment_type', payment_type)
    formData.append('date', date)

    formData.append('quantity', quantity || 0)
    if (amount) formData.append('amount', amount)
    if (currency) formData.append('currency', currency)
    formData.append('description', description || '')

    if (images?.length > 0) {
      //@ts-ignore
      images.forEach((photo, index) => {
        formData.append(`images[${index}]`, photo)
      })
    }

    if (id) {
      formData.set('_method', 'put')
    }

    try {
      const response = await axiosInstance.post(
        `/${URL.reportCommons}/${currentReportCommon?.id}/expenseCommons${id ? `/${id}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        thunkAPI.dispatch(fetchReportsCommon(isPendingAddExpenseCommon))
        if (currentReportCommon?.id) {
          thunkAPI.dispatch(fetchCurrentReportCommon(currentReportCommon?.id))
        }

        return response.data
      }
      throw new Error('Не удалось создать запись')
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при добавлении данных')
    }
  }
)

export const fetchDeleteExpenseCommon = createAsyncThunk(
  'expenses/fetchDeleteExpenseCommon',
  async (_, thunkAPI) => {
    const {
      reportsCommon: {
        currentReportCommon,
        currentExpensesCommon,
        lastReportsQueryCommon,
      },
    } = thunkAPI.getState() as RootState
    try {
      const response = await axiosInstance.delete(
        `/${URL.reportCommons}/${currentReportCommon?.id}/expenseCommons/${currentExpensesCommon?.id}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        thunkAPI.dispatch(fetchReportsCommon(lastReportsQueryCommon))
        if (currentReportCommon?.id) {
          // thunkAPI.dispatch(fetchCurrentReportCommon(currentReportCommon?.id))
        }

        return { ...response.data, id: currentExpensesCommon?.id }
      }
      throw new Error('Не удалось удалить запись')
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при удалении данных')
    }
  }
)
export const fetchDeleteExpenseImageCommon = createAsyncThunk(
  'expenses/fetchDeleteExpense',
  async ({ imageId, currentExpensesId }: any, thunkAPI) => {
    const {
      reportsCommon: { currentReportCommon, lastReportsQueryCommon },
    } = thunkAPI.getState() as RootState
    try {
      // eports/5/expenses/14/image/10

      const response = await axiosInstance.delete(
        `/${URL.reportCommons}/${currentReportCommon?.id}/expenseCommons/${currentExpensesId}/image/${imageId}`
      )

      if (response.statusText === 'OK' || response.statusText === 'Created') {
        thunkAPI.dispatch(fetchReportsCommon(lastReportsQueryCommon))
        if (currentReportCommon?.id) {
          thunkAPI.dispatch(fetchCurrentReportCommon(currentReportCommon?.id))
        }

        return { ...response.data, id: currentExpensesId }
      }
      throw new Error('Не удалось удалить запись')
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при удалении данных')
    }
  }
)

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setSelectedReportsTab: (state, action) => {
      return { ...state, currentReportTab: action.payload }
    },
    setCurrentReportCommon: (state, action) => {
      return { ...state, currentReportCommon: action.payload }
    },
    setIsVisibleModalReportCommon: (state, action: { payload: boolean }) => {
      return {
        ...state,
        isVisibleModalReportCommon: action.payload,
        currentReportCommon: action.payload ? state.currentReportCommon : null,
      }
    },
    setIsVisibleDeleteModalReportCommon: (
      state,
      action: { payload: boolean }
    ) => ({
      ...state,
      isVisibleDeleteModalReportCommon: action.payload,
    }),

    setCurrentReportId: (state, action) => {
      return { ...state, currentReportId: action.payload }
    },

    // expenses
    setCurrentExpensesCommon: (state, action) => {
      return { ...state, currentExpensesCommon: action.payload }
    },
    setCurrentIncoming: (state, action) => {
      return { ...state, currentIncoming: action.payload }
    },
    clearExpenses: (state) => {
      return { ...state, expenses: [] }
    },
    setVisibleDeleteModalExpensesCommon: (
      state,
      action: { payload: boolean }
    ) => ({
      ...state,
      isVisibleDeleteModalExpensesCommon: action.payload,
    }),
    setVisibleDeleteModalIncoming: (state, action: { payload: boolean }) => ({
      ...state,
      isVisibleDeleteModalIncoming: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportsCommon.pending, (state, action) => {
        return { ...state, isPendingGetReportsCommon: true }
      })
      .addCase(fetchReportsCommon.fulfilled, (state, action) => {
        return {
          ...state,
          reportsCommon: action.payload,
          lastReportsQueryCommon: action.meta.arg,
          isPendingGetReportsCommon: false,
        }
      })
      .addCase(fetchReportsCommon.rejected, (state, action) => {
        toast.error(action.payload as string)

        return { ...state, isPendingGetReportsCommon: false }
      })

      .addCase(fetchCurrentReportCommon.pending, (state, action) => {
        return { ...state, isPendingGetCurrentReportsCommon: true }
      })
      .addCase(fetchCurrentReportCommon.fulfilled, (state, action) => {
        return {
          ...state,
          currentReportCommon: action.payload,
          isPendingGetCurrentReportsCommon: false,
        }
      })
      .addCase(fetchCurrentReportCommon.rejected, (state, action) => {
        toast.error(action.payload as string)

        return { ...state, isPendingGetCurrentReportsCommon: false }
      })

      .addCase(fetchAddReportCommon.pending, (state, action) => {
        return state
      })
      .addCase(fetchAddReportCommon.fulfilled, (state, action) => {
        toast.success('Сохранено!')
        const isUserExist = current(state).reportsCommon.find(
          (item) => item.id === action.payload.id
        )

        if (isUserExist) {
          return {
            ...state,
            isVisibleModalReportCommon: false,
            reportsCommon: state.reportsCommon.map((item) =>
              item.id === action.payload.id ? action.payload : item
            ),
          }
        }
        return {
          ...state,
          reportsCommon: sortByDate([action.payload, ...state.reportsCommon]),
          isVisibleModalReportCommon: false,
        }
      })
      .addCase(fetchAddReportCommon.rejected, (state, action) => {
        toast.error(action.payload as string)
        return {
          ...state,
          isVisibleModalReportCommon: false,
        }
      })
    //   .addCase(fetchReportFile.pending, (state, action) => {
    //     return state
    //   })
    //   .addCase(fetchReportFile.fulfilled, (state, action) => {
    //     return state
    //   })
    //   .addCase(fetchReportFile.rejected, (state, action) => {
    //     toast.error(action.payload as string)

    //     return state
    //   })

    builder.addCase(fetchDeleteReportCommon.pending, (state) => {
      return state
    })
    builder.addCase(fetchDeleteReportCommon.fulfilled, (state, action) => {
      toast.success('Удалено!')

      return {
        ...state,
        isVisibleDeleteModalReportCommon: false,
        currentReportCommon: null,
        reportsCommon: state.reportsCommon.filter(
          (item) => item.id !== state?.currentReportCommon?.id
        ),
      }
    })
    builder
      .addCase(fetchDeleteReportCommon.rejected, (state, action) => {
        toast.error(action.payload as string)
        return { ...state, isVisibleDeleteModalReportCommon: false }
      })

      //   // expensers
      //   .addCase(fetchExpenses.pending, (state) => {
      //     state.isLoading = true
      //     state.error = null
      //   })
      //   .addCase(
      //     fetchExpenses.fulfilled,
      //     (state, action: PayloadAction<Expense[]>) => {
      //       state.expenses = action.payload
      //       state.isLoading = false
      //     }
      //   )
      //   .addCase(fetchExpenses.rejected, (state, action) => {
      //     state.isLoading = false
      //     state.error = action.payload as string
      //   })

      .addCase(fetchAddExpenseCommon.pending, (state) => {
        state.isPendingAddExpenseCommon = true
      })
      .addCase(fetchAddExpenseCommon.fulfilled, (state, action) => {
        toast.success('Сохранено!')

        if (state.currentReportCommon) {
          const isExist = state.currentReportCommon.expense_commons?.find(
            (expense) => expense.id === action.payload.id
          )

          if (isExist) {
            state.currentReportCommon.expense_commons =
              state.currentReportCommon.expense_commons.map((expense) =>
                expense.id === action.payload.id ? action.payload : expense
              )

            // state.reports = state.reports.map((report) =>
            //   //@ts-ignore
            //   report.id === state?.currentReportCommon.id
            //     ? state.currentReportCommon
            //     : report
            // )
          } else {
            // state.reports = state.reports.map((report) =>
            //   //@ts-ignore
            //   report.id === state?.currentReportCommon.id
            //     ? state.currentReportCommon
            //     : report
            // )

            state.currentReportCommon.expense_commons = [
              action.payload,
              ...(state.currentReportCommon.expense_commons || []),
            ]
          }
        }

        state.isPendingAddExpenseCommon = false
      })
      .addCase(fetchAddExpenseCommon.rejected, (state, action) => {
        state.isPendingAddExpenseCommon = false
        state.isRejectAddExpenseCommon = true
      })

      .addCase(fetchDeleteExpenseCommon.pending, (state) => {
        state.isPendingDeleteExpenseCommon = true
      })

      .addCase(
        fetchDeleteExpenseCommon.fulfilled,
        (state, action: PayloadAction<{ id: number }>) => {
          toast.success('Удалено!')

          if (state.currentReportCommon) {
            state.currentReportCommon.expense_commons =
              state.currentReportCommon.expense_commons.filter(
                (expense) => expense.id !== action.payload.id
              )
            state.reportsCommon = state.reportsCommon.map((report) =>
              //@ts-ignore
              report.id === state?.currentReportCommon.id
                ? state.currentReportCommon
                : report
            )
          }
          state.isVisibleDeleteModalExpensesCommon = false
          state.isPendingDeleteExpenseCommon = false
          state.isFulfilledDeleteExpenseCommon = true
        }
      )

      .addCase(fetchDeleteExpenseCommon.rejected, (state, action) => {
        toast.error(action.payload as string)

        state.isVisibleDeleteModalExpensesCommon = false
        state.isPendingDeleteExpenseCommon = false
        state.isRejectDeleteExpenseCommon = true
        // state.error = action.payload as string
      })

    //   // INCOMING
    //   .addCase(fetchAddIncoming.pending, (state) => {
    //     state.isPendingAddIncoming = true
    //   })
    //   .addCase(fetchAddIncoming.fulfilled, (state, action) => {
    //     toast.success('Сохранено!')

    //     if (state.currentReport) {
    //       const isExist = state.currentReport.incomings?.find(
    //         (incoming) => incoming.id === action.payload.id
    //       )

    //       if (isExist) {
    //         state.currentReport.incomings = state.currentReport.incomings.map(
    //           (incoming) =>
    //             incoming.id === action.payload.id ? action.payload : incoming
    //         )

    //         // state.reports = state.reports.map((report) =>
    //         //   //@ts-ignore
    //         //   report.id === state?.currentReport.id
    //         //     ? state.currentReport
    //         //     : report
    //         // )
    //       } else {
    //         // state.reports = state.reports.map((report) =>
    //         //   //@ts-ignore
    //         //   report.id === state?.currentReport.id
    //         //     ? state.currentReport
    //         //     : report
    //         // )

    //         state.currentReport.expenses = [
    //           action.payload,
    //           ...(state.currentReport.expenses || []),
    //         ]
    //       }
    //     }
    //     state.isPendingAddIncoming = false
    //   })
    //   .addCase(fetchAddIncoming.rejected, (state, action) => {
    //     state.isPendingAddIncoming = false
    //     state.isRejectAddIncoming = true
    //     state.error = action.payload as string
    //   })

    //   .addCase(fetchDeleteIncoming.pending, (state) => {
    //     state.isPendingDeleteIncoming = true
    //   })

    //   .addCase(
    //     fetchDeleteIncoming.fulfilled,
    //     (state, action: PayloadAction<{ id: number }>) => {
    //       toast.success('Удалено!')

    //       if (state.currentReport) {
    //         state.currentReport.incomings =
    //           state.currentReport.incomings.filter(
    //             (incoming) => incoming.id !== action.payload.id
    //           )
    //         state.reports = state.reports.map((report) =>
    //           //@ts-ignore
    //           report.id === state?.currentReport.id
    //             ? state.currentReport
    //             : report
    //         )
    //       }
    //       state.isVisibleDeleteModalIncoming = false
    //       state.isPendingDeleteIncoming = false
    //       state.isFulfilledDeleteIncoming = true
    //     }
    //   )

    //   .addCase(fetchDeleteIncoming.rejected, (state, action) => {
    //     toast.error(action.payload as string)

    //     state.isVisibleDeleteModalIncoming = false
    //     state.isPendingDeleteIncoming = false
    //     state.isRejectDeleteIncoming = true
    //     state.error = action.payload as string
    //   })
  },
})

export const {
  setCurrentReportCommon,
  setIsVisibleModalReportCommon,
  setIsVisibleDeleteModalReportCommon,
  clearExpenses,
  setVisibleDeleteModalExpensesCommon,
  setCurrentExpensesCommon,
  setCurrentReportId,

  setCurrentIncoming,
  setVisibleDeleteModalIncoming,
  setSelectedReportsTab,
} = reportsSlice.actions

export default reportsSlice.reducer
