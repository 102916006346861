import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'

import styles from './ReportModal.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../../redux/store'
import {
  fetchAddReports,
  fetchCurrentReport,
  setIsVisibleModalReport,
} from '../../../redux/reducers/reports'
import classNames from 'classnames'
import { carsSelector } from '../../../redux/selectors/cars-selector'
import { reportsSelector } from '../../../redux/selectors/reports-selector'

import { fetchUsers } from '../../../redux/reducers/users'
import { driversAndAssistantsSelector } from '../../../redux/selectors/users-selector'
import DateTimePicker from '../../common/date-picker/DatePicker'
import { fetchCars } from '../../../redux/reducers/cars'

import { PATH } from '../../../constants/path'
import { useClearBodyNoScrollClass } from '../../../hooks/use-clear-body-no-scroll-slass'
import {
  onlyIntegerCheck,
  onlyNumberRegularCheck,
} from '../../../utils/onlyNumberRegularCheck'
import { getHost } from '../../../utils/getHost'

import AllExpenses from './expenses/AllExpenses'
import AllIncomings from './incomings/AllIncomings'
import { reportsCommonSelector } from '../../../redux/selectors/reports-common-selector'
import ReportCommonModal from './ReportCommonModal'
import { setIsVisibleModalReportCommon } from '../../../redux/reducers/reports-common'
import { useOutsideClick } from '../../../hooks/useOutsideClick'

function ReportModal() {
  const dispatch = useDispatch<AppDispatch>()

  const { currentReport, currentReportId } = useSelector(reportsSelector)
  const { currentReportTab } = useSelector(reportsCommonSelector)

  useClearBodyNoScrollClass()

  const { cars } = useSelector(carsSelector)
  // DATA
  const [user, setUser] = useState(currentReport?.user?.id || '')
  const [fuelBefore, setFuelBefore] = useState(currentReport?.fuel_before || '')
  const [fuelAfter, setFuelAfter] = useState(currentReport?.fuel_after || '')
  const [mileageAfter, setMileageAfter] = useState(
    currentReport?.mileage_after || ''
  )
  const [mileageBefore, setMileageBefore] = useState(
    currentReport?.mileage_before || ''
  )
  const [departure, setDeparture] = useState(currentReport?.departure || '')
  const [arrival, setArrival] = useState(currentReport?.arrival || '')

  const [crossingBorderBack, setCrossingBorderBack] = useState(
    currentReport?.crossing_border_back || ''
  )
  const [crossingBorderThere, setCrossingBorderThere] = useState(
    currentReport?.crossing_border_there || ''
  )
  const [reimbursementDate, setReimbursementDate] = useState(
    currentReport?.reimbursement_date || ''
  )
  const [trailer, setTrailer] = useState(currentReport?.trailer?.id || '')
  const [car, setCar] = useState(currentReport?.car.id || '')
  const [status, setStatus] = useState(currentReport?.status)
  const [avgCons, setAvgCons] = useState(currentReport?.avg_cons || '')

  const [issuedByn, setIssuedByn] = useState(currentReport?.issued_byn || '')
  const [issuedBynBalance, setIssuedBynBalance] = useState(
    currentReport?.issued_byn_balance || ''
  )
  const [issuedEur, setIssuedEur] = useState(currentReport?.issued_eur || '')
  const [issuedEurBalance, setIssuedEurBalance] = useState(
    currentReport?.issued_eur_balance || ''
  )
  const [issuedRub, setIssuedRub] = useState(currentReport?.issued_rub || '')
  const [issuedRubBalance, setIssuedRubBalance] = useState(
    currentReport?.issued_rub_balance || ''
  )

  const [number, setNumber] = useState(currentReport?.number || '')

  useEffect(() => {
    if (currentReport) {
      setUser(currentReport.user.id || '')
      setFuelBefore(currentReport.fuel_before || '')
      setFuelAfter(currentReport.fuel_after || '')
      setMileageAfter(currentReport.mileage_after || '')
      setMileageBefore(currentReport.mileage_before || '')
      setDeparture(currentReport.departure || '')
      setArrival(currentReport.arrival || '')
      setCrossingBorderBack(currentReport.crossing_border_back || '')
      setCrossingBorderThere(currentReport.crossing_border_there || '')
      setReimbursementDate(currentReport.reimbursement_date || '')
      setTrailer(currentReport.trailer?.id || '')
      setCar(currentReport.car.id || '')
      setStatus(currentReport.status)
      setAvgCons(currentReport.avg_cons || '')
      setIssuedByn(currentReport.issued_byn || '')
      setIssuedBynBalance(currentReport.issued_byn_balance || '')
      setIssuedEur(currentReport.issued_eur || '')
      setIssuedEurBalance(currentReport.issued_eur_balance || '')
      setIssuedRub(currentReport.issued_rub || '')
      setIssuedRubBalance(currentReport.issued_rub_balance || '')
      setNumber(currentReport.number || '')
    }
  }, [currentReport])

  const isNewReport = !!currentReport
  const isEditMyReport = window.location.pathname === PATH.MY_REPORTS

  // my_report && not new report && status draft
  const isEditDraftReport =
    isEditMyReport && isNewReport && currentReport?.status === 'draft'

  const [errors, setErrors] = useState<{
    departure: boolean

    mileageBefore: boolean
    car: boolean
    // trailer: boolean
    fuelBefore: boolean

    mileageAfter?: boolean
    fuelAfter?: boolean
    arrival?: boolean
    user?: boolean
  }>({
    departure: false,
    mileageBefore: false,
    car: false,
    // trailer: false,
    fuelBefore: false,

    mileageAfter: false,
    fuelAfter: false,
    arrival: false,
    user: false,
  })

  const checkValidation = () => {
    let newErrors = {
      departure: !departure,
      mileageBefore: !mileageBefore,
      car: !car,
      fuelBefore: !fuelBefore,
      // trailer: !trailer,

      mileageAfter: false,
      fuelAfter: false,
      arrival: false,
      user: false,
    }

    if (!!currentReport?.id && currentReport.status !== 'draft') {
      newErrors = {
        ...newErrors,
        mileageAfter: !mileageAfter,
        fuelAfter: !fuelAfter,
        arrival: !arrival,
        user: !user,
      }
    }
    setErrors(newErrors)
  }
  const handleSaveReport = (e: any, statusReport?: string) => {
    e.preventDefault()

    const commonParams = {
      departure,
      mileageBefore,
      car,
      fuelBefore,
      fuelAfter,
      mileageAfter,
      trailer,
      crossingBorderThere,
      crossingBorderBack,
      avgCons,
      reimbursementDate,
      status: statusReport || status,
      issuedByn,
      issuedEur,
      issuedRub,
      user,
      arrival,
      number,
    }

    const newErrors = {
      departure: !departure,
      mileageBefore: !mileageBefore,
      car: !car,
      // trailer: !trailer,
      fuelBefore: !fuelBefore,
    }

    setErrors(newErrors)

    const hasErrors = Object.values(newErrors).some((error) => error)

    if (!hasErrors) {
      if (currentReportId) {
        dispatch(fetchAddReports({ ...commonParams, id: currentReportId }))
      } else {
        dispatch(fetchAddReports(commonParams))
      }
    }
  }

  const handleSentReport = (e: any) => {
    e.preventDefault()
    const commonParams = {
      departure,
      mileageBefore,
      car,
      fuelBefore,
      fuelAfter,
      mileageAfter,
      trailer,
      crossingBorderThere,
      crossingBorderBack,
      reimbursementDate,
      status: isEditMyReport ? 'sent' : status,
      arrival,
      avgCons,
      user: !isEditMyReport ? user : null,
      issuedByn,
      issuedEur,
      issuedRub,
      number,
    }

    const newErrors = {
      departure: !departure,
      mileageBefore: !mileageBefore,
      car: !car,
      fuelBefore: !fuelBefore,

      mileageAfter: !mileageAfter,
      fuelAfter: !fuelAfter,
      arrival: !arrival,
      // crossingBorderThere: !crossingBorderThere,
      // crossingBorderBack: !crossingBorderBack,
    }
    if (!isEditMyReport) {
      //@ts-ignore
      newErrors.user = !user
    }

    setErrors(newErrors)

    const hasErrors = Object.values(newErrors).some((error) => error)

    if (hasErrors) {
      setIsVisibleConfirmModal(false)
    }
    if (!hasErrors) {
      dispatch(
        fetchAddReports({
          ...commonParams,
          id: currentReportId,
          user: user ? user : 'ME',
        })
      )
    }
  }

  const handleOpenConfirmModal = (e: any) => {
    const newErrors = {
      departure: !departure,
      mileageBefore: !mileageBefore,
      car: !car,
      fuelBefore: !fuelBefore,

      mileageAfter: !mileageAfter,
      fuelAfter: !fuelAfter,
      arrival: !arrival,
      user: !user,
      // crossingBorderThere: !crossingBorderThere,
      // crossingBorderBack: !crossingBorderBack,
    }

    setErrors(newErrors)

    const hasErrors = Object.values(newErrors).some((error) => error)
    if (hasErrors) {
      setIsVisibleConfirmModal(false)
    }
    if (!hasErrors) {
      setIsVisibleConfirmModal(true)
    }
  }

  useEffect(() => {
    if (currentReportTab === '1') {
      dispatch(fetchUsers())
      dispatch(fetchCars())
    }
  }, [])

  const driversAndAssistants = useSelector(driversAndAssistantsSelector)

  useEffect(() => {
    if (currentReportId) {
      dispatch(fetchCurrentReport(currentReportId))
    }
  }, [])

  const [isVisibleConfirmModal, setIsVisibleConfirmModal] = useState(false)

  function formatNumber(number: number) {
    let roundedNumber = number.toFixed(2)
    if (roundedNumber.endsWith('.00')) {
      return Math.floor(number).toString()
    } else {
      return roundedNumber
    }
  }

  function ConfirmModal() {
    return (
      <div style={{ display: 'block' }} className={styles.win}>
        <div className={styles.winContainer}>
          <div className={styles.winContent}>
            <div
              onClick={() => setIsVisibleConfirmModal(false)}
              className={styles.close}
            >
              ×
            </div>
            <div className={styles.winContentFulltext}>
              <h3
                style={{ textAlign: 'center' }}
                className={classNames(styles.h3)}
              >
                Подать отчет?
              </h3>
              <div
                className={classNames(
                  'row',
                  styles.small2,
                  styles.borderRadius5,
                  styles.rowNote,
                  styles.bxShadow
                )}
              >
                <div className={classNames(styles.confirmText)}>
                  Отправив отчёт, вы уже не сможете его смотреть, редактировать
                  или удалить.
                </div>
              </div>
              <div className="row mt-3">
                <div
                  className="col-6 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'left' }}
                >
                  <button
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn,
                      styles.btnSmall
                    )}
                    onClick={(e) => {
                      e.preventDefault()
                      handleSentReport(e)
                    }}
                  >
                    <i className={`${styles.icon} ${styles.iconCheckWhite}`} />{' '}
                    Отправить
                  </button>
                </div>
                <div
                  className="col-6 col-sm-6 mt-1 mb-1"
                  style={{ textAlign: 'right' }}
                >
                  <div
                    onClick={() => setIsVisibleConfirmModal(false)}
                    className={classNames(
                      'd-block',
                      'd-sm-inline-block',
                      styles.btn,
                      styles.btnSmall,
                      styles.btnBlueOutline
                    )}
                  >
                    Отмена
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const [isVisibleDownloadList, setIsVisibleDownloadList] = useState(false)
  const ref = useOutsideClick(() => {
    setIsVisibleDownloadList(false)
  })

  useEffect(() => {
    //here
    const getAllExpensesByFuel = currentReport?.expenses
      .filter((item) => item.type === 'fuel')
      .reduce((sum, item) => sum + (item?.quantity || 0), 0)

    //@ts-ignore
    if (
      mileageBefore &&
      mileageAfter &&
      fuelBefore !== undefined &&
      fuelAfter !== undefined
    ) {
      const mileage = Number(mileageAfter) - Number(mileageBefore)

      const fuel =
        (getAllExpensesByFuel || 0) + Number(fuelBefore) - Number(fuelAfter)

      // if (fuel > 0) {
      const avgConsumption = fuel / mileage
      setAvgCons((Number(avgConsumption) * 100).toFixed(3))
      // }
    }
  }, [fuelAfter, mileageAfter, currentReport])

  const [currentTab, setCurrentTab] = useState<number>(1)

  return (
    <div style={{ display: 'block' }} className={styles.win}>
      {isVisibleConfirmModal && <ConfirmModal />}

      <div className={styles.winContainer}>
        <div className={styles.winContent}>
          <div
            onClick={() => {
              dispatch(setIsVisibleModalReportCommon(false))
              dispatch(setIsVisibleModalReport(false))
            }}
            className={styles.close}
          >
            ×
          </div>
          {currentReportTab === '1' ? (
            <div className={styles.winContentFulltext}>
              <div className="row align-items-center mt-4 mb-5">
                <div className="col-7">
                  <h3
                    style={{ textAlign: 'left' }}
                    className={classNames(styles.h3, 'mb-0 pl-0 pr-0')}
                  >
                    {currentReport
                      ? `Отчёт ${currentReport?.number ? '№ ' + currentReport?.number : 'Б/Н'}`
                      : 'Новый отчёт'}
                  </h3>
                </div>
                {!isEditMyReport && !!currentReport && (
                  <div className="col-5" style={{ textAlign: 'right' }}>
                    {currentReport?.report_pdf_url && (
                      <div
                        //@ts-ignore
                        ref={ref}
                        onClick={() =>
                          setIsVisibleDownloadList((prev) => !prev)
                        }
                        className={classNames(
                          styles.dropdown,
                          styles.dropdownLeft
                        )}
                      >
                        <div
                          className={classNames(
                            styles.btn,
                            styles.btnSmall,
                            styles.btnOutline,
                            styles.btnIcon,
                            styles.btnDropdown
                          )}
                        >
                          <i
                            className={classNames(
                              styles.icon,
                              styles.iconDownload
                            )}
                          />
                        </div>
                        {isVisibleDownloadList && (
                          <div
                            className={classNames(
                              styles.dropdownContent,
                              styles.btnSmall2
                            )}
                          >
                            {(currentReport?.report_pdf_url ||
                              currentReport?.report_pdf_advance_url) && (
                              <a
                                target="_blank"
                                href={`${getHost()}${currentReport?.report_pdf_url}`}
                                className="nowrap"
                                rel="noreferrer"
                              >
                                Топливный отчёт
                              </a>
                            )}
                            {currentReport?.report_pdf_advance_url && (
                              <a
                                target="_blank"
                                href={`${getHost()}${currentReport?.report_pdf_advance_url}`}
                                className="nowrap"
                                rel="noreferrer"
                              >
                                Авансовый отчёт
                              </a>
                            )}{' '}
                            {/* <a target="_blank" href="#" className="nowrap">
                            Командировочное
                          </a>
                          <a target="_blank" href="#" className="nowrap">
                            Приказ
                          </a> */}
                          </div>
                        )}
                      </div>
                    )}

                    {currentReport?.report_images_url && (
                      <a
                        className={classNames(
                          styles.btn,
                          styles.btnSmall2,
                          styles.btnBlueOutline,
                          styles.btnIcon
                        )}
                        target="_blank"
                        href={`${getHost()}${currentReport?.report_images_url}`}
                        rel="noreferrer"
                      >
                        <i
                          className={classNames(
                            styles.icon,
                            styles.iconPicture,
                            styles.iconPictureWithoutPadding
                          )}
                        />
                      </a>
                    )}
                  </div>
                )}
              </div>
              {!!currentReport && (
                <div
                  className={classNames(
                    'd-flex flex-wrap align-items-end',
                    styles.radioTabsHead,
                    styles.small
                  )}
                >
                  <div
                    className={classNames(
                      styles.menuButton,
                      currentTab === 1 ? styles.menuButtonActive : '',
                      ''
                    )}
                    onClick={() => setCurrentTab(1)}
                  >
                    Основное
                  </div>
                  <div
                    className={classNames(
                      styles.menuButton,
                      currentTab === 2 ? styles.menuButtonActive : ''
                      // 'col-4 col-sm-4 col-md-4 mt-2'
                    )}
                    onClick={() => setCurrentTab(2)}
                  >
                    Расходы
                  </div>
                  <div
                    className={classNames(
                      styles.menuButton,
                      currentTab === 3 ? styles.menuButtonActive : ''
                      // 'col-4 col-sm-4 col-md-4 mt-3'
                    )}
                    onClick={() => setCurrentTab(3)}
                  >
                    Приходы
                  </div>
                </div>
              )}

              {currentTab === 1 && (
                <form
                  onSubmit={(e) => {
                    if (
                      (status === 'sent' || status === 'closed') &&
                      !isEditMyReport
                    ) {
                      handleSentReport(e)
                    } else {
                      handleSaveReport(e, 'draft')
                    }
                  }}
                >
                  {/* <!-- ТОЛЬКО ДЛЯ АДМИНА ИЛИ ВЛАДЕЛЬЦА --> */}
                  {!isEditMyReport && (
                    <div
                      className={classNames(
                        styles.bxShadowSmall,
                        styles.bgGray,
                        styles.border10,
                        styles.small2,
                        styles.docItem,
                        'pt-2 pb-2 pl-3 pr-3 mb-3 '
                      )}
                    >
                      <div
                        className={classNames(
                          styles.row,
                          'row',
                          'align-items-start'
                        )}
                      >
                        <div className="col-6 col-sm-2 mt-1 mb-1">
                          <small
                            className={classNames(
                              styles.small2,
                              'd-block mb-1'
                            )}
                          >
                            Номер П/Л
                          </small>
                          <input
                            value={number}
                            onChange={(e) => {
                              setNumber(e.target.value)
                            }}
                            type="text"
                          />
                        </div>

                        <div className="col-6 col-sm-4 mt-1 mb-1">
                          <small
                            className={classNames(
                              styles.small2,
                              'd-block mb-1'
                            )}
                          >
                            Водитель
                          </small>
                          <select
                            value={user}
                            onChange={(e) => {
                              setUser(e.target.value)
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                user: false,
                              }))
                            }}
                            className={classNames(
                              errors.user ? styles.isError : ''
                            )}
                          >
                            <option value="" key={undefined}>
                              — Выберите —
                            </option>
                            {driversAndAssistants.map((user) => {
                              return (
                                <option key={user.id} value={user.id}>
                                  {user.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>

                        <div className="col-6 col-sm-3 mt-1 mb-1">
                          <small
                            className={classNames(
                              styles.small2,
                              'd-block mb-1'
                            )}
                          >
                            Ср. расход, л/100
                          </small>
                          <input
                            className={styles.disableInput}
                            value={
                              avgCons && isFinite(+avgCons) && +avgCons > 0
                                ? avgCons
                                : 0
                            }
                            onChange={(e) => setAvgCons(e.target.value)}
                            type="text"
                            disabled={true}
                          />
                        </div>
                        <div className="col-6 col-sm-3 mt-1 mb-1">
                          <small
                            className={classNames(
                              styles.small2,
                              'd-block mb-1'
                            )}
                          >
                            Статус
                          </small>
                          <select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            disabled={!currentReport}
                          >
                            <option key="draft" value="draft" selected>
                              Черновик
                            </option>
                            <option key="sent" value="sent">
                              Отправлен
                            </option>
                            <option key="closed" value="closed">
                              Закрыт
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <!-- / ТОЛЬКО ДЛЯ АДМИНА ИЛИ ВЛАДЕЛЬЦА --> */}

                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                      <label
                        htmlFor="report_date_input_departure"
                        className={classNames(
                          styles.small2,
                          styles.label,
                          'd-block mb-1'
                        )}
                      >
                        Дата и время выезда*
                      </label>
                      <div
                        className={classNames(
                          styles.reportDate,
                          styles.border5,
                          errors.departure ? styles.isError : ''
                        )}
                        onClick={() => {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            departure: false,
                          }))
                        }}
                        id="report_date_input_wrapper1"
                      >
                        <DateTimePicker
                          id="report_date_input_departure"
                          selectedDate={departure}
                          setSelectedDate={setDeparture}
                          isValidateDate={false}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                      <div className="row">
                        <div className="col-6 mb-3 pr-1">
                          <small
                            className={classNames(
                              styles.small2,
                              'd-block mb-1'
                            )}
                          >
                            Пробег До*, км
                          </small>
                          <input
                            className={
                              errors.mileageBefore ? styles.isError : ''
                            }
                            value={mileageBefore}
                            onChange={(e) => {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                mileageBefore: false,
                              }))

                              if (onlyIntegerCheck(e)) {
                                setMileageBefore(e.target.value)
                              }
                            }}
                            required
                            type="text"
                          />
                        </div>
                        <div className="col-6 mb-3 pl-1">
                          <small
                            className={classNames(
                              styles.small2,
                              'd-block mb-1'
                            )}
                          >
                            Пробег После, км
                          </small>
                          <input
                            className={
                              errors.mileageAfter ? styles.isError : ''
                            }
                            value={mileageAfter}
                            onChange={(e) => {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                mileageAfter: false,
                              }))

                              if (onlyIntegerCheck(e)) {
                                setMileageAfter(e.target.value)
                              }
                            }}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                      <label
                        htmlFor="report_date_input_arrival"
                        className={classNames(
                          styles.small2,
                          styles.label,
                          'd-block mb-1'
                        )}
                      >
                        Дата и время прибытия*
                      </label>
                      <div
                        className={classNames(
                          styles.reportDate,
                          styles.border5,
                          errors.arrival ? styles.isError : ''
                        )}
                        onClick={() => {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            arrival: false,
                          }))
                        }}
                        id="report_date_input_wrapper1"
                      >
                        <DateTimePicker
                          id="report_date_input_arrival"
                          selectedDate={arrival}
                          setSelectedDate={setArrival}
                          isValidateDate={false}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-6 mb-3 pr-1">
                          <small
                            className={classNames(
                              styles.small2,
                              'd-block mb-1'
                            )}
                          >
                            Топливо До*, л
                          </small>
                          <input
                            className={errors.fuelBefore ? styles.isError : ''}
                            value={fuelBefore}
                            onChange={(e) => {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                fuelBefore: false,
                              }))

                              if (onlyNumberRegularCheck(e)) {
                                setFuelBefore(e.target.value.replace(',', '.'))
                              }
                            }}
                            type="text"
                          />
                        </div>
                        <div className="col-6 mb-3 pl-1">
                          <small
                            className={classNames(
                              styles.small2,
                              'd-block mb-1'
                            )}
                          >
                            Топливо После, л
                          </small>
                          <input
                            className={errors.fuelAfter ? styles.isError : ''}
                            value={fuelAfter}
                            onChange={(e) => {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                fuelAfter: false,
                              }))

                              if (onlyNumberRegularCheck(e)) {
                                setFuelAfter(e.target.value.replace(',', '.'))
                              }
                            }}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className={classNames(
                        styles.mobilePaddingRight,
                        'col-6 col-sm-6 mb-3'
                      )}
                    >
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Грузовик*
                      </small>
                      <select
                        className={errors.car ? styles.isError : ''}
                        value={car}
                        onChange={(e) => {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            car: false,
                          }))

                          setCar(e.target.value)
                        }}
                      >
                        <option value="" disabled hidden />
                        {cars.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className={classNames(
                        styles.mobilePaddingLeft,
                        'col-6 col-sm-6 mb-3'
                      )}
                    >
                      <small
                        className={classNames(styles.small2, 'd-block mb-1')}
                      >
                        Прицеп
                      </small>
                      <select
                        value={trailer}
                        onChange={(e) => {
                          setTrailer(e.target.value)
                        }}
                      >
                        <option value="" key={undefined}>
                          — Выберите —
                        </option>
                        {cars.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                      <label
                        htmlFor="report_date_input_tuda"
                        className={classNames(
                          styles.small2,
                          styles.label,
                          'd-block mb-1'
                        )}
                      >
                        Пересечение границы туда
                      </label>
                      <DateTimePicker
                        id="report_date_input_tuda"
                        selectedDate={crossingBorderThere}
                        setSelectedDate={setCrossingBorderThere}
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                      <label
                        htmlFor="report_date_input_obratno"
                        className={classNames(
                          styles.small2,
                          styles.label,
                          'd-block mb-1'
                        )}
                      >
                        Пересечение границы обратно
                      </label>
                      <DateTimePicker
                        id="report_date_input_obratno"
                        selectedDate={crossingBorderBack}
                        setSelectedDate={setCrossingBorderBack}
                      />
                    </div>

                    {currentReportId && (
                      <>
                        {isEditMyReport ? (
                          (issuedByn ||
                            issuedRub ||
                            issuedEur ||
                            issuedBynBalance ||
                            issuedRubBalance ||
                            issuedEurBalance) && (
                            <div className="col-12 mt-3 mb-2">
                              <div className="mb-2">
                                <b>Выдано в рейс</b>
                              </div>
                              <div className="row">
                                {(issuedByn || issuedBynBalance) && (
                                  <div
                                    className={classNames(
                                      styles.small,
                                      'col-4 pr-0 small'
                                    )}
                                  >
                                    <div className="mb-1">
                                      <b>{issuedByn || 0}</b>{' '}
                                      <span
                                        className={classNames(
                                          styles.small3,
                                          styles.colorGray
                                        )}
                                      >
                                        BYN
                                      </span>
                                    </div>
                                    <div className={classNames(styles.small3)}>
                                      Ост.{' '}
                                      <b className={styles.colorRed}>
                                        {formatNumber(+issuedBynBalance) || 0}
                                      </b>{' '}
                                      <span
                                        className={classNames(
                                          styles.small3,
                                          styles.colorRed
                                        )}
                                      >
                                        BYN
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {(issuedRub || issuedRubBalance) && (
                                  <div
                                    className={classNames(
                                      styles.small,
                                      'col-4 pr-0 small'
                                    )}
                                  >
                                    <div className="mb-1">
                                      <b>{issuedRub || 0}</b>{' '}
                                      <span
                                        className={classNames(
                                          styles.small3,
                                          styles.colorGray
                                        )}
                                      >
                                        RUB
                                      </span>
                                    </div>
                                    <div className={classNames(styles.small3)}>
                                      Ост.{' '}
                                      <b className={styles.colorRed}>
                                        {formatNumber(+issuedRubBalance) || 0}
                                      </b>{' '}
                                      <span
                                        className={classNames(
                                          styles.small3,
                                          styles.colorRed
                                        )}
                                      >
                                        RUB
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {(issuedEur || issuedEurBalance) && (
                                  <div
                                    className={classNames(
                                      styles.small,
                                      'col-4 pr-0 small'
                                    )}
                                  >
                                    <div className="mb-1">
                                      <b>{issuedEur || 0}</b>{' '}
                                      <span
                                        className={classNames(
                                          styles.small3,
                                          styles.colorGray
                                        )}
                                      >
                                        EUR
                                      </span>
                                    </div>
                                    <div className={classNames(styles.small3)}>
                                      Ост.{' '}
                                      <b className={styles.colorRed}>
                                        {formatNumber(+issuedEurBalance) || 0}
                                      </b>{' '}
                                      <span
                                        className={classNames(
                                          styles.small3,
                                          styles.colorRed
                                        )}
                                      >
                                        EUR
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        ) : (
                          <div className="col-12 mt-3 mb-2">
                            <b className="mb-2">Деньги</b>

                            <div
                              className={classNames(
                                styles.small2,
                                'row align-items-start'
                              )}
                            >
                              <div
                                className={classNames(
                                  styles.small2,
                                  'col-12 col-sm-8 mt-1 mb-1'
                                )}
                              >
                                <small
                                  className={classNames(
                                    styles.small2,
                                    'd-block mb-1'
                                  )}
                                >
                                  Выдано в рейс
                                </small>
                                <div className="row">
                                  <div
                                    className={classNames(
                                      styles.small2,
                                      'col-4 pr-1'
                                    )}
                                  >
                                    <div
                                      className={classNames(
                                        styles.inputCurrency,
                                        styles.inputByn,
                                        'mb-1'
                                      )}
                                    >
                                      <input
                                        value={issuedByn}
                                        onChange={(e) => {
                                          if (onlyNumberRegularCheck(e)) {
                                            setIssuedByn(
                                              e.target.value.replace(',', '.')
                                            )
                                          }
                                        }}
                                        type="text"
                                        placeholder="Сумма"
                                      />
                                    </div>
                                    <div className={classNames(styles.small3)}>
                                      Ост.{' '}
                                      <b
                                        className={classNames(styles.colorRed)}
                                      >
                                        {formatNumber(+issuedBynBalance) || 0}
                                      </b>{' '}
                                      <span
                                        className={classNames(styles.colorRed)}
                                      >
                                        BYN
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    className={classNames(
                                      styles.small2,
                                      'col-4 pl-1 pr-1'
                                    )}
                                  >
                                    <div
                                      className={classNames(
                                        styles.inputCurrency,
                                        styles.inputRub,
                                        'mb-1'
                                      )}
                                    >
                                      <input
                                        value={issuedRub}
                                        onChange={(e) => {
                                          if (onlyNumberRegularCheck(e)) {
                                            setIssuedRub(
                                              e.target.value.replace(',', '.')
                                            )
                                          }
                                        }}
                                        type="text"
                                        placeholder="Сумма"
                                      />
                                    </div>
                                    <div className={classNames(styles.small3)}>
                                      Ост.{' '}
                                      <b
                                        className={classNames(styles.colorRed)}
                                      >
                                        {formatNumber(+issuedRubBalance) || 0}
                                      </b>{' '}
                                      <span
                                        className={classNames(styles.colorRed)}
                                      >
                                        RUB
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    className={classNames(
                                      styles.small2,
                                      'col-4 pl-1'
                                    )}
                                  >
                                    <div
                                      className={classNames(
                                        styles.inputCurrency,
                                        styles.inputEur,
                                        'mb-1'
                                      )}
                                    >
                                      <input
                                        value={issuedEur}
                                        onChange={(e) => {
                                          if (onlyNumberRegularCheck(e)) {
                                            setIssuedEur(
                                              e.target.value.replace(',', '.')
                                            )
                                          }
                                        }}
                                        type="text"
                                        placeholder="Сумма"
                                      />
                                    </div>
                                    <div className={classNames(styles.small3)}>
                                      Ост.{' '}
                                      <b
                                        className={classNames(styles.colorRed)}
                                      >
                                        {formatNumber(+issuedEurBalance) || 0}
                                      </b>{' '}
                                      <span
                                        className={classNames(styles.colorRed)}
                                      >
                                        {' '}
                                        EUR
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={classNames(
                                  styles.small2,
                                  'col-12 col-sm-4 mt-1 mb-1'
                                )}
                              >
                                <small
                                  className={classNames(
                                    styles.small2,
                                    'd-block mb-1'
                                  )}
                                >
                                  Возмещ. расходов
                                </small>
                                <div
                                  className="report-date"
                                  id="money_date_input_wrapper"
                                >
                                  <DateTimePicker
                                    id="money_date_input_wrapper"
                                    selectedDate={reimbursementDate}
                                    setSelectedDate={setReimbursementDate}
                                    addPosition={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {!isEditDraftReport ? (
                    <div className="row mt-2">
                      <div
                        className="col-12 col-sm-3 mt-1 mb-1"
                        style={{ textAlign: 'left' }}
                      ></div>
                      <div
                        className="col-12 col-sm-6 mt-1 mb-1"
                        style={{ textAlign: 'center' }}
                      >
                        <button
                          onClick={checkValidation}
                          className={classNames(
                            styles.btn,
                            'd-block',
                            'd-sm-inline-block'
                          )}
                        >
                          <i
                            className={classNames(
                              styles.icon,
                              styles.iconCheckWhite
                            )}
                          />{' '}
                          Сохранить
                        </button>
                      </div>
                      <div
                        className="col-12 col-sm-3 mt-1 mb-1"
                        style={{ textAlign: 'right' }}
                      >
                        <div
                          className={classNames(
                            styles.btn,
                            styles.btnSmall,
                            styles.btnBlueOutline,
                            'd-block d-sm-inline-block'
                          )}
                          onClick={() => {
                            dispatch(setIsVisibleModalReport(false))
                            dispatch(setIsVisibleModalReportCommon(false))
                          }}
                        >
                          Отмена
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-2">
                      <div
                        className="col-12 col-sm-9 mt-1 mb-1"
                        style={{ textAlign: 'left' }}
                      >
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            handleSaveReport(e, 'draft')
                          }}
                          style={{ marginRight: '10px' }}
                          className={classNames(
                            styles.btn,
                            styles.btnBlue,
                            'mt-1 d-block d-sm-inline-block'
                          )}
                        >
                          Сохранить
                        </button>
                        <button
                          className={classNames(
                            styles.btn,
                            'd-block mt-1 d-sm-inline-block'
                          )}
                          onClick={(e) => {
                            e.preventDefault()
                            handleOpenConfirmModal(e)
                            // e.preventDefault()
                            // handleSentReport(e)
                          }}
                        >
                          <i
                            className={classNames(
                              styles.icon,
                              styles.iconCheckWhite
                            )}
                          ></i>{' '}
                          Отправить
                        </button>
                      </div>
                      <div
                        className="col-12 col-sm-3 mt-1 mb-1"
                        style={{ textAlign: 'right' }}
                      >
                        <div
                          className={classNames(
                            styles.btn,
                            styles.btnSmall,
                            styles.btnBlueOutline,
                            'd-block d-sm-inline-block'
                          )}
                          onClick={() =>
                            dispatch(setIsVisibleModalReport(false))
                          }
                        >
                          Отмена
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              )}
              {currentTab === 2 && <AllExpenses />}
              {currentTab === 3 && (
                <AllIncomings
                  issuedByn={issuedByn}
                  issuedRub={issuedRub}
                  issuedEur={issuedEur}
                  issuedBynBalance={issuedBynBalance}
                  issuedRubBalance={issuedRubBalance}
                  issuedEurBalance={issuedEurBalance}
                  setIssuedRub={setIssuedRub}
                  setReimbursementDate={setReimbursementDate}
                  reimbursementDate={reimbursementDate}
                  setIssuedEur={setIssuedEur}
                  setIssuedByn={setIssuedByn}
                />
              )}
            </div>
          ) : (
            <ReportCommonModal />
          )}
        </div>
      </div>
    </div>
  )
}

export default ReportModal
