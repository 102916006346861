import React, { useState } from 'react'
import AllExpensesCommon from './expenses/AllExpensesCommon'
import styles from './ReportModal.module.css'
import classNames from 'classnames'
import { getHost } from '../../../utils/getHost'
import { useDispatch, useSelector } from 'react-redux'
import { reportsCommonSelector } from '../../../redux/selectors/reports-common-selector'
import { AppDispatch } from '../../../redux/store'
import DateTimePicker from '../../common/date-picker/DatePicker'
import {
  fetchAddReportCommon,
  setIsVisibleModalReportCommon,
} from '../../../redux/reducers/reports-common'

function ReportCommonModal() {
  const dispatch = useDispatch<AppDispatch>()
  const { reportsCommon, currentReportCommon } = useSelector(
    reportsCommonSelector
  )

  const [number, setNumber] = useState(currentReportCommon?.number || '')
  const [status, setStatus] = useState(currentReportCommon?.status)
  const [departure, setDeparture] = useState(
    currentReportCommon?.departure || ''
  )

  const [reimbursementDate, setReimbursementDate] = useState(
    currentReportCommon?.reimbursement_date || ''
  )
  const [arrival, setArrival] = useState(currentReportCommon?.arrival || '')

  const handleSave = () => {
    const commonObj = {
      number,
      status,
      departure,
      arrival,
      reimbursementDate,
    }

    if (currentReportCommon) {
      dispatch(
        fetchAddReportCommon({ ...commonObj, id: currentReportCommon.id })
      )
    } else {
      dispatch(fetchAddReportCommon(commonObj))
    }
  }

  return (
    <div
      className={classNames(styles.winContentFulltext, styles.minHeightCommon)}
    >
      <div className="row align-items-center mt-4 mb-4">
        <div className="col-7">
          <h3 className="mb-0 pl-0 pr-0">
            {currentReportCommon
              ? `Отчёт ${currentReportCommon?.number ? '№ ' + currentReportCommon?.number : 'Б/Н'}`
              : 'Новый отчёт'}
          </h3>
        </div>
        <div className="col-5" style={{ textAlign: 'right' }}>
          {currentReportCommon?.report_pdf_url && (
            <a
              className={classNames(
                styles.btn,
                styles.btnSmall2,
                styles.btnBlueOutline,
                styles.btnIcon,

                'ml-1 mr-1'
              )}
              href={`${getHost()}${currentReportCommon?.report_pdf_url}`}
              target="_blank"
              rel="noreferrer"
            >
              <i
                className={classNames(
                  styles.icon,
                  styles.iconDownload,
                  styles.iconPictureWithoutPadding
                )}
              />
            </a>
          )}
          {currentReportCommon?.report_images_url && (
            <a
              className={classNames(
                styles.btn,
                styles.btnSmall2,
                styles.btnBlueOutline,
                styles.btnIcon
              )}
              target="_blank"
              href={`${getHost()}${currentReportCommon?.report_images_url}`}
              rel="noreferrer"
            >
              <i
                className={classNames(
                  styles.icon,
                  styles.iconPicture,
                  styles.iconPictureWithoutPadding
                )}
              />
            </a>
          )}
        </div>
      </div>

      <form className={styles.formReport} onSubmit={(e) => e.preventDefault()}>
        <div className="row small">
          <div className="col-6 col-sm-2 pr-1 mb-2">
            <small className="small-2 d-block mb-1">№</small>
            <input
              value={number}
              onChange={(e) => {
                setNumber(e.target.value)
              }}
              type="text"
            />
          </div>
          <div className="col-6 col-sm-4 pl-1 mb-2">
            <small className="small-2 d-block mb-1">Статус</small>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              // disabled={!currentReport}
            >
              <option key="draft" value="draft" selected>
                Черновик
              </option>
              <option key="sent" value="sent">
                Отправлен
              </option>
              <option key="closed" value="closed">
                Закрыт
              </option>
            </select>
          </div>
          <div className="col-6 col-sm-3 pr-1 mb-2">
            <small className="small-2 d-block mb-1">Начало*</small>
            <div className="report-date" id="common_report_date_input_wrapper">
              <DateTimePicker
                id="money_date_input_wrapper"
                selectedDate={departure}
                setSelectedDate={setDeparture}
                addPosition={true}
                timeFormat={false}
              />{' '}
            </div>
          </div>
          <div className="col-6 col-sm-3 pl-1 mb-2">
            <small className="small-2 d-block mb-1">Конец</small>
            <div
              className="report-date"
              id="common_report_date_input_wrapper_end"
            >
              <DateTimePicker
                id="money_date_input_wrapper"
                selectedDate={arrival}
                setSelectedDate={setArrival}
                addPosition={true}
                timeFormat={false}
              />
            </div>
          </div>
        </div>
        {currentReportCommon?.id && (
          <AllExpensesCommon
            reimbursementDate={reimbursementDate}
            setReimbursementDate={setReimbursementDate}
          />
        )}
        <div className="row mt-4">
          <div
            className="col-12 col-sm-3 mt-1 mb-1"
            style={{ textAlign: 'left' }}
          ></div>
          <div
            className="col-12 col-sm-6 mt-1 mb-1"
            style={{ textAlign: 'center' }}
          >
            <button
              onClick={handleSave}
              disabled={!departure}
              className={classNames(styles.btn, 'd-block', 'd-sm-inline-block')}
            >
              <i className={classNames(styles.icon, styles.iconCheckWhite)} />{' '}
              Сохранить
            </button>
          </div>
          <div
            className="col-12 col-sm-3 mt-1 mb-1"
            style={{ textAlign: 'right' }}
          >
            <div
              className={classNames(
                styles.btn,
                styles.btnSmall,
                styles.btnBlueOutline,
                'd-block d-sm-inline-block'
              )}
              onClick={() => {
                dispatch(setIsVisibleModalReportCommon(false))
              }}
            >
              Отмена
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ReportCommonModal
