import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns'

const currentYear = new Date().getFullYear()

export const DEFINEDS = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: (year: number) => startOfYear(new Date(year, 0, 1)),
  endOfYear: (year: number) => endOfYear(new Date(year, 0, 1)),
}

export const staticRangesArray = [
  {
    label: 'Вчера',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOfYesterday,
      endDate: DEFINEDS.endOfYesterday,
    }),
  },
  {
    label: 'Этот месяц',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOfMonth,
      endDate: DEFINEDS.endOfMonth,
    }),
  },
  {
    label: 'Прошлый месяц',
    isSelected: () => true,
    range: () => ({
      startDate: DEFINEDS.startOfLastMonth,
      endDate: DEFINEDS.endOfLastMonth,
    }),
  },
  ...[0, 1, 2].map((offset) => {
    const year = currentYear - offset
    return {
      label: `${year}`,
      isSelected: () => true,
      range: () => ({
        startDate: DEFINEDS.startOfYear(year),
        endDate: DEFINEDS.endOfYear(year),
      }),
    }
  }),
]
