import { createSlice } from '@reduxjs/toolkit'
import { Range } from 'react-date-range'
import { DEFINEDS } from '../../constants/date'

export interface InitState {
  calendarRange: Array<Range>
  calendarRangeCommon: Array<Range>
  selectedTrucks: { id: string; name: string }[]
  selectedDrivers: { id: string; name: string }[]
  selectedStatus: string[]
}

const getSessionStorageData = (key: string) => {
  const data = sessionStorage.getItem('reportFilter')
  return data ? JSON.parse(data)[key] : null
}
const calendarStorage = getSessionStorageData('calendarRange')
const calendarCommonStorage = getSessionStorageData('calendarRangeCommon')

const storedSelectedTrucks = getSessionStorageData('selectedTrucks')
const storedSelectedDrivers = getSessionStorageData('selectedDrivers')
const storedSelectedStatus = getSessionStorageData('selectedStatus')

const initialState: InitState = {
  calendarRangeCommon: [
    {
      startDate: calendarCommonStorage?.[0]
        ? new Date(calendarCommonStorage[0].startDate)
        : DEFINEDS.startOfMonth,
      endDate: calendarCommonStorage?.[0]
        ? new Date(calendarCommonStorage[0].endDate)
        : DEFINEDS.endOfMonth,
      key: 'selection',
    },
  ],
  calendarRange: [
    {
      startDate: calendarStorage?.[0]
        ? new Date(calendarStorage[0].startDate)
        : DEFINEDS.startOfMonth,
      endDate: calendarStorage?.[0]
        ? new Date(calendarStorage[0].endDate)
        : DEFINEDS.endOfMonth,
      key: 'selection',
    },
  ],
  selectedTrucks: storedSelectedTrucks?.length ? storedSelectedTrucks : [],
  selectedDrivers: storedSelectedDrivers?.length ? storedSelectedDrivers : [],
  selectedStatus: storedSelectedStatus?.length
    ? storedSelectedStatus
    : ['draft', 'sent'],
}

const updateSessionStorage = (key: string, value: any) => {
  const currentData = JSON.parse(sessionStorage.getItem('reportFilter') || '{}')
  currentData[key] = value
  sessionStorage.setItem('reportFilter', JSON.stringify(currentData))
}

export const reportFilterSlice = createSlice({
  name: 'reportFilter',
  initialState,
  reducers: {
    setCalendarRange: (state, action) => {
      const newState = { ...state, calendarRange: action.payload }
      updateSessionStorage('calendarRange', action.payload)
      return newState
    },
    setCalendarRangeCommon: (state, action) => {
      const newState = { ...state, calendarRangeCommon: action.payload }
      updateSessionStorage('calendarRangeCommon', action.payload)
      return newState
    },
    setSelectedTrucks: (state, action) => {
      state.selectedTrucks = action.payload
      updateSessionStorage('selectedTrucks', action.payload)
    },
    setSelectedDrivers: (state, action) => {
      state.selectedDrivers = action.payload
      updateSessionStorage('selectedDrivers', action.payload)
    },
    setSelectedStatus: (state, action) => {
      state.selectedStatus = action.payload
      updateSessionStorage('selectedStatus', action.payload)
    },
  },
})

export const {
  setCalendarRange,
  setSelectedTrucks,
  setSelectedDrivers,
  setSelectedStatus,
  setCalendarRangeCommon,
} = reportFilterSlice.actions

export default reportFilterSlice.reducer
